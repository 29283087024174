import { UserIcon, HomeIcon, UsersIcon, CogIcon, ClockIcon, BuildingOfficeIcon, ArrowLeftStartOnRectangleIcon, CurrencyEuroIcon, ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import { checkPageAccess, isAdmin, isSuperAdmin } from "../utils/User";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { Cog8ToothIcon } from "@heroicons/react/24/outline";

function Sidebar() {
  const location = useLocation();

  return (
    <aside id="sidebar" className="bg-gray-50 max-h-screen fixed top-0 left-0 bottom-0 text-gray-900 md:w-64 w-3/4 space-y-6 pt-6 px-0 inset-y-0 transform md:relative md:translate-x-0 transition duration-200 ease-in-out md:flex md:flex-col md:justify-between overflow-y-auto z-50" data-dev-hint="sidebar; px-0 for frameless; px-2 for visually inset the navigation">
      <div className="flex flex-col space-y-6" data-dev-hint="optional div for having an extra footer navigation">
        <Link to="/" className="text-gray flex items-center space-x-2 px-4" title="CBS Social">
          <img src={require('../assets/img/logo.png')} alt="CBS Social" className="w-20 h-20" />
          <span className="text-2xl font-bold ">Social</span>
        </Link>

        <nav data-dev-hint="main navigation" className="mt-10">
          <NavLinkWithActiveCheck to="/" currentLocation={location} icon={<HomeIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Dashboard" />
          {(checkPageAccess('liste_des_salariees') || checkPageAccess('liste_des_salariees')) ?
            <NavLinkWithActiveCheck to="/employees" currentLocation={location} icon={<UsersIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Gestion des salariées" />
            :
            null
          }
          {(checkPageAccess('les_variables_salariales') || checkPageAccess('les_variables_salariales_consulter')) ?
            <NavLinkWithActiveCheck to="/variables" currentLocation={location} icon={<CurrencyEuroIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Les variables salariales" />
            :
            null
          }
          {(checkPageAccess('gestion_des_absences') || checkPageAccess('gestion_des_absences_consulter')) ?
            <NavLinkWithActiveCheck to="/absences" currentLocation={location} icon={<ClipboardDocumentListIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Gestion des absences" />
            :
            null
          }
          {
            (isSuperAdmin() || isAdmin()) ?
            <>
              {(checkPageAccess('liste_des_entreprises') || checkPageAccess('liste_des_entreprises_consulter')) ?
                <NavLinkWithActiveCheck to="/companies" currentLocation={location} icon={<BuildingOfficeIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Gestion des entreprises" />
                :
                null
              }
              {(checkPageAccess('gestion_des_declaration') || checkPageAccess('gestion_des_declaration_consulter')) ?
                <NavLinkWithActiveCheck to="/declarations" currentLocation={location} icon={<ClipboardDocumentListIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Gestion des déclarations" />
                :
                null
              }
              {(checkPageAccess('historique_des_activites') || checkPageAccess('historique_des_activites_consulter')) ?
                <NavLinkWithActiveCheck to="/logs" currentLocation={location} icon={<ClockIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Historique des activités" />
                :
                null
              }
            </>
              : null
          }
          {(checkPageAccess('gestion_des_comptes') || checkPageAccess('gestion_des_comptes_consulter')) ?
            <NavLinkWithActiveCheck to="/accounts" currentLocation={location} icon={<CogIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Gestion des comptes" />
            :
            null
          }
          {
            isSuperAdmin() ?
            <NavLinkWithActiveCheck to="/settings" currentLocation={location} icon={<Cog8ToothIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />} text="Administration" />
            : null
          }
        </nav>
      </div>

      <nav data-dev-hint="second-main-navigation or footer navigation">
        <Link to="/profile" className="flex items-center space-x-2 py-3 px-4 mb-1 transition duration-200 hover:bg-gray-200 hover:rounded-2xl selected:bg-white">
          <UserIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />
          <span>
            {
              jwtDecode(Cookies.get('token')).fullname
            }
          </span>
        </Link>
        <div onClick={() => {
          Cookies.remove('token');
          window.location.href = '/';
        }} className="flex items-center space-x-2 py-3 px-4 mb-1 transition duration-200 hover:bg-gray-200 hover:rounded-2xl selected:bg-white cursor-pointer">
          <ArrowLeftStartOnRectangleIcon className="flex-shrink-0 w-5 h-5 mr-2 text-indigo-700" />
          <span>Se déconnecter</span>
        </div>
      </nav>
    </aside>
  );
}

function NavLinkWithActiveCheck({ to, currentLocation, icon, text }) {
  const isActive = currentLocation.pathname === to;
  const classNames = `flex items-center space-x-2 py-3 px-4 mb-1 transition duration-200 hover:bg-gray-200 selected:bg-white ${isActive ? 'border-l-[3px] border-indigo-700 hover:rounded-r-2xl bg-gray-200' : 'hover:rounded-2xl '}`;
  return (
    <a href={to} className={classNames}>
      {icon}
      <span>{text}</span>
    </a>
  );
}

export default Sidebar;
