import { Alert, Spinner } from 'flowbite-react';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { publicRequest } from '../makeRequest';

const Login = () => {

  let navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState(null);
  const [connecting, setConnecting] = useState(false);

  const Login = async (e) => {
    e.preventDefault();
    setConnecting(true);
    setErrorMessage(null);
    let email = e.target.email.value;
    let password = e.target.password.value;

    try {
      const response = await publicRequest.post('login_check', {
        username: email,
        password: password
      });

      Cookies.set('token', response.data.token, { expires: 1 });
      navigate(0);
      setConnecting(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage({
          message: 'Adresse email ou mot de passe incorrect',
          type: 'failure'
        });
      } else {
        setErrorMessage({
          message: 'Erreur serveur, veuillez réessayer plus tard #400',
          type: 'failure'
        });
      }
      setConnecting(false);
    }
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm text-center">
        <div className="text-gray flex items-center justify-center space-x-2 px-4" title="CBS Social">
          {/* <span className="text-2xl font-extrabold whitespace-nowrap truncate">CBS Social</span> */}
          <img src={require('../assets/img/logo.png')} alt="CBS Social" className="w-20 h-20" />
          <span className="text-2xl font-bold ">Social</span>
        </div>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Se connecter à votre compte
        </h2>
      </div>
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" action="#" method="POST" onSubmit={(e) => Login(e)}>
          {errorMessage && <Alert color={errorMessage.type}>
            {errorMessage.message}
          </Alert>}
          <div>
            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
              Address email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-700 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Mot de passe
              </label>
              <div className="text-sm">
                <Link to="" className="font-semibold text-indigo-600 hover:text-indigo-500">
                  Mot de passe oublié ?
                </Link>
              </div>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 px-2 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-700 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <button
              type="submit"
              disabled={connecting}
              className="flex w-full justify-center rounded-md bg-indigo-700 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {
                connecting ?
                  <>
                    <Spinner size="sm" className='me-2' />
                    Connexion...
                  </>
                  :
                  'Se connecter'
              }
            </button>
          </div>
        </form>

        {/* <p className="mt-10 text-center text-sm text-gray-500">
          Pas encore de compte ?{' '}
          <a href="#" className="font-semibold leading-6 text-indigo-700 hover:text-indigo-500">
            Start a 14 day free trial
          </a>
        </p> */}
      </div>
    </div>
  )
}

export default Login
