import axios from "axios";
import Cookies from "js-cookie";

const BASE_URL = 'http://social.sascbs.com/v1/public/api/' // process.env.REACT_APP_API_URL || 
// const BASE_URL = 'http://127.0.0.1:8000/api/' // process.env.REACT_APP_API_URL ||

export const publicRequest = axios.create({
    baseURL: BASE_URL,
    headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

export const userRequest = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + Cookies.get('token')
    }
})