import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "flowbite-react";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { Table, Tag, message, Transfer, ConfigProvider, Checkbox, TreeSelect } from "antd";
import { userRequest } from "../makeRequest";
import { checkPageAccess, isAdmin, isSuperAdmin } from "../utils/User";
import dayjs from 'dayjs'
import fr_FR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr';
dayjs.locale('fr');
const { SHOW_PARENT } = TreeSelect;

const userActions = ['les_variables_salariales', 'gestion_des_absences', 'liste_des_salariees', 'gestion_des_comptes'];
const adminActions = ['liste_des_entreprises', 'gestion_des_declaration', 'historique_des_activites'];

const Comptes = () => {
  const [modal, setOpenModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoading, setIsLoading] = useState({ loading: false, title: "" });
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    roles: "",
    companies: [],
    page_access: []
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [filtredUsers, setFiltredUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [deletedUser, setDeletedUser] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [randomPassword, setRandomPassword] = useState(false);
  const [errors, setErrors] = useState({});

  const fetchCompanies = async () => {
    try {
      const res = await userRequest.get("companies_all");
      if (res.status === 200) {
        const newMockData = [];
        const data = res?.data;
        let companyList = [];
        data?.forEach((company) => {
          companyList.push({ id: company.id, value: company.name });
          const mockdata = {
            key: company?.id,
            title: company?.name,
            chosen: false,
          };
          newMockData.push(mockdata);
        })
        setCompanies(newMockData);
        // setSelectedCompanies(newSelectedCompanies);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchUserList = async () => {
    setIsLoading({ loading: true, title: "getUsers" });
    try {
      const res = await userRequest.get("users_list");
      if (res.status === 200) {
        setUsers(res?.data);
        setFiltredUsers(res?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading({ loading: false, title: "" });
    }
  };

  const openDeleteModal = (id) => {
    setDeleteModal(true);
    setDeletedUser(id);
  }
  const closeDeleteModal = () => setDeleteModal(false);
  const closeModal = () => {
    setOpenModal(false);
    setIsEdit(false);
    setUserData({
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      roles: "",
      companies: [],
      page_access: []
    });
  };
  const showModal = () => {
    setSelectedCompanies([]);
    setRandomPassword(false);
    setOpenModal(true);
  };

  const validateForm = () => {
    const errors = {};
    if (!userData.firstname) {
      errors.firstname = "Le nom est requis";
    }
    if (!userData.lastname) {
      errors.lastname = "Le prénom est requis";
    }
    if (!userData.password && !isEdit) {
      errors.password = "Le mot de passe est requis";
    }
    if (!userData.email) {
      errors.email = "L'email est requis";
    } else if (!/\S+@\S+\.\S+/.test(userData.email)) {
      errors.email = "L'email n'est pas valide";
    }
    if (!userData.roles) {
      errors.roles = "Le rôle est requis";
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading({ loading: true, title: "submit" });
    if (validateForm()) {
      try {
        const res = isEdit ?
          await userRequest.patch(`users/${userData.id}`, { ...userData, companies: selectedCompanies }, {
            headers: {
              'Content-Type': 'application/merge-patch+json',
              'Accept': 'application/ld+json',
            }
          })
          : await userRequest.post("users", { ...userData, companies: selectedCompanies }, {
            headers: {
              'Content-Type': 'application/ld+json',
              'Accept': 'application/ld+json',
            }
          });
        if (res.status === 201 || res.status === 200) {
          closeModal();
          message.success('Utilisateur créé avec succès');
          await fetchUserList();
        }
      } catch (error) {
        if (error?.response?.data?.message) {
          setErrors({ email: error?.response?.data?.message });
        } else {
          setErrors({ submit: "Une erreur s'est produite lors de la création de l'utilisateur, veuillez réessayer" });
        }
      }
    }
    setIsLoading({ loading: false, title: "" });
  }

  const handleDelete = async (e) => {
    e.preventDefault();
    setIsLoading({ loading: true, title: "delete" });
    try {
      const res = await userRequest.delete(`users/${deletedUser}`, {
        headers: {
          'Content-Type': 'application/ld+json',
          'Accept': 'application/ld+json',
        }
      });
      if (res.status === 204) {
        closeDeleteModal();
        message.success('Utilisateur supprimé avec succès');
        await fetchUserList();
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading({ loading: false, title: "" });
    }
  }

  useEffect(() => {
    fetchCompanies();
    fetchUserList();
  }, []);

  useEffect(() => {
    if (userData.roles.includes('ROLE_ADMIN')) {
      setUserData(prevUserData => ({
        ...prevUserData,
        page_access: userActions.concat(adminActions)
      }));
    }
    else if(userData.roles.includes('ROLE_USER')) {
      setUserData(prevUserData => ({
        ...prevUserData,
        page_access: userActions
      }));
    }
    else {
      setUserData(prevUserData => ({
        ...prevUserData,
        page_access: []
      }));
    }
  }, [userData?.roles]);

  useEffect(() => {
    if (searchValue) {
      const filterUsers = users?.filter((user) =>
        user?.firstname?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
        user?.lastname?.toLowerCase()?.includes(searchValue.toLowerCase()) ||
        user?.email?.toLowerCase()?.includes(searchValue.toLowerCase())
      );
      setFiltredUsers(filterUsers);
    } else {
      setFiltredUsers(users);
    }
  }, [searchValue, users]);

  const onChange = (newSelectedCompanies, direction, moveKeys) => {
    setSelectedCompanies(newSelectedCompanies);
  };

  const userTreeData = [
    {
      title: 'Les variables salariales',
      value: 'les_variables_salariales',
      key: 'les_variables_salariales',
      children: [
        {
          title: 'Consulter',
          value: 'les_variables_salariales_consulter',
          key: 'les_variables_salariales_consulter',
        },
        {
          title: userData?.roles?.includes('ROLE_ADMIN') ? 'Traiter' : 'Déclarer',
          value: userData?.roles?.includes('ROLE_ADMIN') ? 'les_variables_salariales_traiter' : 'les_variables_salariales_declarer',
          key: userData?.roles?.includes('ROLE_ADMIN') ? 'les_variables_salariales_traiter' : 'les_variables_salariales_declarer',
        },
      ],
    },
    {
      title: 'Gestion des absences',
      value: 'gestion_des_absences',
      key: 'gestion_des_absences',
      children: [
        {
          title: 'Consulter',
          value: 'gestion_des_absences_consulter',
          key: 'gestion_des_absences_consulter',
        },
        {
          title: 'Traiter',
          value: 'gestion_des_absences_traiter',
          key: 'gestion_des_absences_traiter',
        }
      ],
    },
    {
      title: 'Liste des salariées',
      value: 'liste_des_salariees',
      key: 'liste_des_salariees',
      children: [
        {
          title: 'Consulter',
          value: 'liste_des_salariees_consulter',
          key: 'liste_des_salariees_consulter',
        },
        {
          title: 'Ajouter',
          value: 'liste_des_salariees_ajouter',
          key: 'liste_des_salariees_ajouter',
        },
        {
          title: 'Modifier',
          value: 'liste_des_salariees_modifier',
          key: 'liste_des_salariees_modifier',
        },
        {
          title: 'Supprimer',
          value: 'liste_des_salariees_supprimer',
          key: 'liste_des_salariees_supprimer',
        },
      ],
    },
    {
      title: 'Gestion des comptes',
      value: 'gestion_des_comptes',
      key: 'gestion_des_comptes',
      children: [
        {
          title: 'Consulter',
          value: 'gestion_des_comptes_consulter',
          key: 'gestion_des_comptes_consulter',
        },
        {
          title: 'Ajouter',
          value: 'gestion_des_comptes_ajouter',
          key: 'gestion_des_comptes_ajouter',
        },
        {
          title: 'Modifier',
          value: 'gestion_des_comptes_modifier',
          key: 'gestion_des_comptes_modifier',
        },
        {
          title: 'Supprimer',
          value: 'gestion_des_comptes_supprimer',
          key: 'gestion_des_comptes_supprimer',
        },
      ],
    }
  ];

  const adminTreeData = [
    {
      title: 'Liste des entreprises',
      value: 'liste_des_entreprises',
      key: 'liste_des_entreprises',
      children: [
        {
          title: 'Consulter',
          value: 'liste_des_entreprises_consulter',
          key: 'liste_des_entreprises_consulter',
        },
        {
          title: 'Ajouter',
          value: 'liste_des_entreprises_ajouter',
          key: 'liste_des_entreprises_ajouter',
        },
        {
          title: 'Modifier',
          value: 'liste_des_entreprises_modifier',
          key: 'liste_des_entreprises_modifier',
        },
        {
          title: 'Supprimer',
          value: 'liste_des_entreprises_supprimer',
          key: 'liste_des_entreprises_supprimer',
        },
      ],
    },
    {
      title: 'Gestion des déclarations',
      value: 'gestion_des_declaration',
      key: 'gestion_des_declaration',
      children: [
        {
          title: 'Consulter',
          value: 'gestion_des_declaration_consulter',
          key: 'gestion_des_declaration_consulter',
        },
        {
          title: userData?.roles?.includes('ROLE_ADMIN') ? 'Traiter' : 'Déclarer',
          value: userData?.roles?.includes('ROLE_ADMIN') ? 'gestion_des_declaration_traiter' : 'gestion_des_declaration_declarer',
          key: userData?.roles?.includes('ROLE_ADMIN') ? 'gestion_des_declaration_traiter' : 'gestion_des_declaration_declarer',
        },
      ],
    },
    {
      title: 'Historique des activités',
      value: 'historique_des_activites',
      key: 'historique_des_activites',
      children: [
        {
          title: 'Consulter',
          value: 'historique_des_activites_consulter',
          key: 'historique_des_activites_consulter',
        },
      ],
    },
  ];

  const tProps = {
    treeData: userData?.roles?.includes('ROLE_ADMIN') ? userTreeData?.concat(adminTreeData) : userTreeData,
    value: userData?.page_access,
    // onTreeSelectChange,
    onChange: (newValue) => setUserData((prev) => ({...prev, page_access: newValue})),
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: 'Please select',
    style: {
      width: '100%',
    },
    size: 'large'
  };

  const actionColumn = (checkPageAccess('gestion_des_comptes') || checkPageAccess('gestion_des_comptes_modifier') || checkPageAccess('gestion_des_comptes_supprimer')) ?
    [
      {
        title: 'Actions',
        key: 'actions',
        render: (record) => (
          <div className="flex">
            {(checkPageAccess('gestion_des_comptes') || checkPageAccess('gestion_des_comptes_modifier')) ?
              <button
                onClick={() => {
                  setOpenModal(true);
                  setIsEdit(true);
                  setSelectedCompanies(record?.companies);
                  setUserData({ ...record });
                }}
                className="py-2 px-3 font-medium text-indigo-600 hover:text-indigo-500 duration-150 hover:bg-gray-50 rounded-lg"
              >
                Modifier
              </button>
              :
              null
            }
            {(checkPageAccess('gestion_des_comptes') || checkPageAccess('gestion_des_comptes_supprimer')) ?
              <button
                onClick={() => openDeleteModal(record?.id)}
                className="py-2 leading-none px-3 font-medium text-red-600 hover:text-red-500 duration-150 hover:bg-gray-50 rounded-lg"
              >
                Supprimer
              </button>
              :
              null
            }
          </div>
        ),
      }
    ]
  :
    [];

  return (
    <div className="relative min-h-screen md:flex">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8 pt-20 md:pt-6">
        <div className="items-start justify-between md:flex mt-5">
          <div className="max-w-lg">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              Gestion des Comptes
            </h3>
            <p className="text-gray-600 mt-2">
              Page simplifiée pour une gestion efficace et sécurisée des comptes.
            </p>
          </div>
          {(checkPageAccess('gestion_des_comptes') || checkPageAccess('gestion_des_comptes_ajouter')) ?
            <Button onClick={showModal} gradientMonochrome="purple" className="mt-5 md:mt-0">Ajouter</Button>
            :
            null
          }
        </div>
        <div className="mt-5 flex justify-end">
          <div className="relative">
            <svg className="w-6 h-6 text-gray-400 absolute left-3 inset-y-0 my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
            </svg>
            <input
              type="text"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              placeholder="Rechercher..."
              className="w-full pl-12 pr-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 rounded-lg sm:max-w-xs"
            />
          </div>
        </div>
        <ConfigProvider locale={fr_FR}>
          <Table
            className="mt-8"
            dataSource={filtredUsers}
            columns={[
              {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
              },
              {
                title: 'Nom',
                dataIndex: 'firstname',
                key: 'firstname',
              },
              {
                title: 'Prénom(s)',
                dataIndex: 'lastname',
                key: 'lastname',
              },
              {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
              },
              {
                title: 'Roles',
                dataIndex: 'roles',
                key: 'roles',
                render: (record) => record?.includes("ROLE_ADMIN") ?
                  <Tag color="blue">Administrateur</Tag> :
                  record?.includes("ROLE_SUPER_ADMIN") ?
                    <Tag color="purple">Super Administrateur</Tag> :
                      <Tag color="green">Utilisateur</Tag>
              },
            ].concat(actionColumn)}
            loading={isLoading.loading && isLoading.title === "getUsers"}
          />
        </ConfigProvider>
        <Modal show={deleteModal} size="md" onClose={closeDeleteModal} popup>
          <Modal.Header />
          <Modal.Body>
            <div className="text-center">
              <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-[#ff4d4f]" />
              <h3 className="mb-5 text-lg font-normal text-gray-500">
                Êtes-vous sûr de vouloir supprimer cet utilisateur ?
              </h3>
              <div className="flex justify-center gap-6">
                <Button color="gray" onClick={closeDeleteModal}>Non, annuler</Button>
                <Button gradientMonochrome="failure" onClick={handleDelete} disabled={isLoading.loading && isLoading.title === "delete"}>
                  {(isLoading.loading && isLoading.title === "delete") && <Spinner color="failure" aria-label="delete spinner" size="sm" className="mr-2" />}
                  Oui
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={modal} onClose={closeModal}>
          <Modal.Header>{isEdit ? "Modifier l'utilisateur" : "Ajouter l'utilisateur"}</Modal.Header>
          <Modal.Body>
            <div className="space-y-6">
              {errors.submit && <p className="text-[#ff4d4f] text-sm">{errors.submit}</p>}
              <form>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="nom"
                  >
                    Nom <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="nom"
                    type="text"
                    placeholder="Nom"
                    value={userData.firstname}
                    onChange={(e) => setUserData((prev) => ({ ...prev, firstname: e.target.value }))}
                    required
                  />
                  {errors.firstname && <p className="text-[#ff4d4f] text-sm">{errors.firstname}</p>}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="prenom"
                  >
                    Prénom(s) <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="prenom"
                    type="text"
                    placeholder="Prénom(s)"
                    value={userData.lastname}
                    onChange={(e) => setUserData((prev) => ({ ...prev, lastname: e.target.value }))}
                    required
                  />
                  {errors.lastname && <p className="text-[#ff4d4f] text-sm">{errors.lastname}</p>}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Mot de passe {isEdit ? "(Laisser vide pour ne pas changer)" : <span className="text-[#ff4d4f]">*</span>}
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="password"
                    type="password"
                    placeholder="Password"
                    disabled={randomPassword}
                    value={userData.password}
                    onChange={(e) => setUserData((prev) => ({ ...prev, password: e.target.value }))}
                    required
                  />
                  {
                    !isEdit && <div className="flex items-center mt-2">
                      <Checkbox
                        checked={randomPassword}
                        onChange={(v) => {
                          if (v.target.checked) {
                            const random = Math.random().toString(36).slice(-8);
                            setUserData((prev) => ({ ...prev, password: random }));
                          } else {
                            setUserData((prev) => ({ ...prev, password: "" }));
                          }
                          setRandomPassword(v.target.checked);
                        }}
                      />
                      <small className="ml-2">Générer un mot de passe aléatoire et l'envoyer par email</small>
                    </div>
                  }
                  {errors.password && <p className="text-[#ff4d4f] text-sm">{errors.password}</p>}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={userData.email}
                    onChange={(e) => setUserData((prev) => ({ ...prev, email: e.target.value }))}
                    required
                  />
                  {errors.email && <p className="text-[#ff4d4f] text-sm">{errors.email}</p>}
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="tel"
                  >
                    Téléphone
                  </label>
                  <input
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="tel"
                    type="tel"
                    placeholder="Tél"
                    value={userData.phone}
                    onChange={(e) => setUserData((prev) => ({ ...prev, phone: e.target.value }))}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="roles"
                  >
                    Roles <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <select
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    id="roles"
                    value={userData?.roles?.length > 0 ? userData?.roles[0] : ""}
                    onChange={(e) => setUserData((prev) => ({ ...prev, roles: [e.target.value] }))}
                    required
                  >
                    <option value="" disabled>Choisir un rôle</option>
                    {isSuperAdmin() && <option value="ROLE_SUPER_ADMIN">Super admin</option>}
                    {(isSuperAdmin() || isAdmin()) && <option value="ROLE_ADMIN">Admin</option>}
                    <option value="ROLE_USER">Utilisateur</option>
                  </select>
                  {errors?.roles && <p className="text-[#ff4d4f] text-sm">{errors?.roles}</p>}
                </div>
                {(!userData?.roles?.includes("ROLE_SUPER_ADMIN") && !userData?.roles?.includes("")) && <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="roles"
                  >
                    Companies <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <ConfigProvider locale={fr_FR}>
                    <Transfer
                      showSearch
                      showSelectAll
                      dataSource={companies}
                      targetKeys={selectedCompanies}
                      onChange={onChange}
                      filterOption={(inputValue, option) => option.title.toLowerCase().indexOf(inputValue.toLowerCase()) > -1}
                      locale={{
                        itemUnit: 'entreprise',
                        itemsUnit: 'entreprises',
                      }}
                      render={(item) => item.title}
                      listStyle={{
                        width: 300,
                        height: 300,
                      }}
                    />
                  </ConfigProvider>
                </div>}
                {(userData?.roles?.length > 0 && !userData?.roles?.includes('ROLE_SUPER_ADMIN')) && <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="pageAccess"
                  >
                    Pages access <span className="text-[#ff4d4f]">*</span>
                  </label>
                  <TreeSelect {...tProps} id="pageAccess" />
                </div>}
                {/* {(!userData?.roles?.includes("ROLE_ADMIN") &&
                !userData?.roles?.includes("") &&
                isAdmin()) && <div className="mb-4">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="societe"
                  >
                    Société
                  </label>
                  <Autocomplete
                    placeholder="Rechercher une entreprise"
                    className="mt-3"
                    value={userData?.company}
                    options={companies}
                    optionLabel="value"
                    onItemSelect={(v) => {
                      setUserData((prev) => ({ ...prev, company: v.id }));
                    }}
                  />
                </div>} */}
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer className="flex justify-end">
            <Button onClick={closeModal} color="gray">Annuler</Button>
            <Button gradientMonochrome="purple" onClick={handleSubmit} disabled={isLoading.loading && isLoading.title === "submit"}>
              {(isLoading.loading && isLoading.title === "submit") && <Spinner color="purple" aria-label="submit spinner" size="sm" className="mr-2" />}
              {userData?.id ? 'Modifier' : 'Créer'}
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </div>
  );
};

export default Comptes;
