import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { Table, DatePicker, AutoComplete, ConfigProvider } from "antd";
import { userRequest } from "../makeRequest";
import moment from "moment";
import dayjs from "dayjs";
import fr_FR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr';
dayjs.locale('fr');

const { RangePicker } = DatePicker;

const Logs = () => {

  const [users, setUsers] = useState([]);
  const [logs, setLogs] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [dates, setDates] = useState({
    from: moment().format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });

  const fetchUsers = async () => {
    try {
      const res = await userRequest.get("users_list");
      if (res.status === 200) {
        const data = res.data;
        let usersListe = [];
        data.forEach((user) => {
          usersListe.push({ id: user.id, value: user.firstname + ' ' + user.lastname });
        })
        setUsers(usersListe);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchLogs();
    fetchUsers();
  }, []);

  const fetchLogs = async () => {
    setLogs(null);
    try {
      const res = await userRequest.get(`logs?from_date=${dates.from}&to_date=${dates.to}&user_id=${selectedUser ? selectedUser : ""}`);
      if (res.status === 200) {
        setLogs(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchLogs();
  }, [dates, selectedUser]);

  return (
    <div className="relative min-h-screen md:flex">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8 pt-20 md:pt-6">
        <div className="items-start justify-between mt-5">
          <div className="max-w-100">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              Historique des activités
            </h3>
            <p className="text-gray-600 mt-2">
              Consultez les différents activités des utilisateurs.
            </p>
            <div className="flex justify-between w-full mt-10">
              <ConfigProvider locale={fr_FR}>
                <RangePicker
                  allowClear={false}
                  defaultValue={[dayjs(dates.from), dayjs(dates.to)]}
                  onChange={(dates) => {
                    setDates({
                      from: dates[0].format("YYYY-MM-DD"),
                      to: dates[1].format("YYYY-MM-DD"),
                    });
                  }}
                  format={"YYYY-MM-DD"}
                />
              </ConfigProvider>
              <div className="flex items-center relative">
                <AutoComplete
                  size='large'
                  className='text-left mx-auto'
                  options={users}
                  style={{ width: 300 }}
                  onSelect={(company, option) => {
                    setSelectedUser(option.id);
                  }}
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  allowClear={true}
                  onClear={() => {
                    setSelectedUser(null);
                  }}
                  placeholder="Rechercher un utilisateur"
                />
              </div>

            </div>
          </div>
        </div>
        <ConfigProvider locale={fr_FR}>
          <Table
            className="mt-5"
            dataSource={logs}
            loading={!logs}
            columns={[
              {
                title: "Utilisateur",
                dataIndex: "user",
                key: "user",
                render: (user) => {
                  return user?.firstname + " " + user?.lastname;
                },
              },
              {
                title: "Action",
                dataIndex: "action",
                key: "action",
              },
              {
                title: "Date",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (createdAt) => {
                  return new Date(createdAt).toLocaleString();
                },
              },
            ]}
            pagination={{ pageSize: 10 }}
          />
        </ConfigProvider>
      </main>
    </div>
  );
};

export default Logs;
