import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import {
  Tabs,
  Modal,
  Table,
  TextInput,
  Alert,
  Button,
  Spinner,
} from "flowbite-react";
import { ConfigProvider, Select, Table as Tbl, message } from "antd";

import {
  CheckIcon,
  Bars3Icon,
  CheckCircleIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import moment from 'moment';
import dayjs from 'dayjs';
import fr_FR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr';
import { userRequest } from '../makeRequest';
dayjs.locale('fr');

const { Option } = Select;


const Companies = () => {
  // const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fetchingCompany, setFetchingCompany] = useState(false);
  const [creatingCompany, setCreatingCompany] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  // const [filterOption, setFilterOption] = useState('name');
  const [selectedCompany, setSelectedCompany] = useState({});
  const [companies, setCompanies] = useState(null);
  const [step, setStep] = useState(0);
  const [managers, setManagers] = useState([])
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [alertMessage, setAlertMessage] = useState(null);
  const [administrators, setAdministrators] = useState([]);
  const [companyFound, setCompanyFound] = useState(false);
  const [listUrssaf, setListUrssaf] = useState([]);
  const [listRetraite, setListRetraite] = useState([]);
  const [companyData, setCompanyData] = useState({
    'name': '',
    'siret': '',
    'siren': '',
    'date_immatriculation': '',
    'start_date': '',
    'nature': '',
    'form': '',
    'activity': '',
    'code_naf': '',
    'capital': '',
    'address': '',
    'department': '',
    'convention': '',
    'nbEmployees': '',
    'urssaf': '',
    'caisseDeRetraite': '',
    'prevoyance': '',
    'mutuelle': '',
    'medecine': '',
    'opco': '',
  });
  const clear = useRef();

  useEffect(() => {
    const fetchUrssaf = async () => {
      try {
        const response = await userRequest.get("urssafs", {
          headers: {
            'Content-Type': 'application/ld+json',
            'Accept': 'application/ld+json',
          }
        })
        const data = response.data['hydra:member'];
        setListUrssaf(data.map((item) => ({ id: item.libelleOrganisme, value: item.libelleOrganisme })));
      }
      catch (error) {
        console.error('Error:', error);
      }
    }

    const fetchRetraite = async () => {
      try {
        const response = await userRequest.get("retraites", {
          headers: {
            'Content-Type': 'application/ld+json',
            'Accept': 'application/ld+json',
          }
        })
        const data = response.data['hydra:member'];
        setListRetraite(data.map((item) => ({ id: item.nom, value: item.nom })));
      }
      catch (error) {
        console.error('Error:', error);
      }
    }

    fetchUrssaf();
    fetchRetraite();
    getCompanies();
    (async () => {
      const response = await userRequest.get('managers_list');
      if (response.status === 200) {
        const data = response?.data;
        setManagers(data);
      }
    })();
  }, []);

  const getCompanies = async (search = '') => {
    setCompanies(null);
    try {
      const response = await userRequest.get(`companies${search ? '?search=' + search : ''}`,
        {
          headers: {
            'Content-Type': 'application/ld+json',
            'Accept': 'application/ld+json',
          }
        }
      );
      const data = response.data;
      setCompanies(data);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  const fetchCompany = async (search = '', page = 1) => {
    setFetchingCompany(true);
    setAlertMessage(null);
    try {
      const response = await userRequest(`company/${companyData.siret}`);
      const data = response.data;
      if (data.name) {
        setCompanyData(data);
        setCompanyFound(true);

        const administratorsArray = Array.isArray(data?.administrators) ? data?.administrators : [data?.administrators];
        setAdministrators(prevAdministrators => [...prevAdministrators, ...administratorsArray.map(administrator => ({
          firstname: administrator.nom,
          lastname: administrator.prenom,
          email: ''
        }))]);

      }
    } catch (error) {
      console.error('Error:', error);
    }
    setFetchingCompany(false);
  }


  useEffect(() => {
    setCompanyData({});
    setCompanyFound(false);
    setAlertMessage(null);
  }, [showAddModal])

  const addCompany = async () => {
    setCreatingCompany(true);
    try {
      const response = await userRequest.post("companies",
        { company: companyData, administrators: administrators, managers: selectedManagers },
        {
          headers: {
            'Content-Type': 'application/ld+json',
            'Accept': 'application/ld+json',
          }
        }
      );
      const data = response.data;
      if (data.error) {
        setAlertMessage({
          type: 'failure',
          message: data.error
        })
      } else {
        setStep(0);
        setCompanyData({});
        setCompanyFound(false);
        setAdministrators([]);
        setShowAddModal(false);
        getCompanies();
        message.success('Entreprise ajoutée avec succès');
      }
      setCreatingCompany(false);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }
  return (
    <div className="relative min-h-screen md:flex" data-dev-hint="container">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8 pt-20 md:pt-6">
        <div className="items-start justify-between md:flex">
          <div className="max-w-lg">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              Gestion des entreprises
            </h3>
            <p className="text-gray-600 mt-2 text-sm">
              Gérez les entreprises de votre plateforme
            </p>
          </div>
          <button onClick={() => setShowAddModal(true)} className='bg-green-600 border-dark border rounded-xl inline-flex items-center justify-center py-2 px-7 text-center text-base font-medium text-white hover:bg-green-700 hover:border-body-color'>
            <PlusIcon className='h-6 w-6' />
            Ajouter une entreprise
          </button>
        </div>
        <div className="max-w-7xl mx-auto mt-10">

          <div className="flex items-center">
            <span className="text-sm me-2">Rechercher </span>
            <input ref={clear} type="text" className="border border-gray-300 rounded-md px-2 py-1 placeholder-gray-500 placeholder-opacity-25" placeholder="Recherche par nom"
              onChange={(e) => {
                if (e.target.value === '') {
                  getCompanies();
                } else if (e.target.value.length > 3) {
                  getCompanies(e.target.value);
                }
              }}
            />
          </div>
          <ConfigProvider locale={fr_FR}>
            <Tbl
              className=" mt-5"
              dataSource={companies}
              columns={[
                {
                  title: 'Nom',
                  dataIndex: 'name',
                },
                {
                  title: 'SIRET',
                  dataIndex: 'siret',
                },
                {
                  title: 'SIREN',
                  dataIndex: 'siren',
                },
                {
                  title: 'Date d\'immatriculation',
                  render: (record) => (
                    <span>{moment(record.dateImmatriculation).format('DD/MM/YYYY')}</span>
                  )
                },
                {
                  title: 'Début d\'activité',
                  render: (record) => (
                    <span>{moment(record.startDate).format('DD/MM/YYYY')}</span>
                  )
                },
                {
                  title: 'Actions',
                  render: (record) => (
                    <div className="flex">
                      <button
                        className="flex font-sm border bg-gray-900 text-white px-2 py-1 rounded"
                        onClick={() => {
                          setSelectedCompany(record);
                          setShowModal(true);
                        }}
                      >
                        <Bars3Icon className="h-5 w-5" />
                        <span className="text-sm">Détails</span>
                      </button>
                    </div>
                  ),
                },

                // {
                //   title: 'Actions',
                //   render: (record) => (
                //     <div className="flex">
                //       <button
                //         className="flex font-sm border bg-gray-900 text-white px-2 py-1 rounded"
                //         onClick={() => {
                //           setEmployeeInfo({
                //             ...record,
                //             documents: employees.find((employee) => employee.id === record.id).documents
                //           });

                //           // setTimeout(() => {
                //           setOpenInfoModal(true);
                //           // }, 100);
                //         }}
                //       >
                //         <DocumentIcon className="h-5 w-5 me-1" />
                //         <span className="text-sm">Voir détails</span>
                //       </button>
                //       <button
                //         className="flex font-sm border bg-blue-600 text-white px-2 py-1 rounded ms-2"
                //         onClick={() => {
                //           setOpenModal(true);
                //           setEmployeeInfo(record);
                //         }}
                //       >
                //         <PencilIcon className="h-5 w-5 me-1" />
                //         <span className="text-sm">Modifier</span>
                //       </button>
                //       <button
                //         className="flex font-sm border bg-red-600 text-white px-2 py-1 rounded ms-2"
                //         onClick={deleteUser(record.id)}
                //       >
                //         <ArchiveBoxArrowDownIcon className="h-5 w-5" />
                //         {/* <span className="text-sm">Supprimer</span> */}
                //       </button>
                //     </div>
                //   ),
                // },
              ]}
            />
          </ConfigProvider>
          {/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mt-10">
            {
              companies ?
                companies.map((company, index) => {
                  return (
                    <div key={index} className="bg-gray-100 shadow-sm rounded-md p-4">
                      <h5 className='font-bold whitespace-nowrap overflow-hidden overflow-ellipsis'>{company.name}</h5>
                      <p className='px-2 py-1 text-xs whitespace-nowrap overflow-hidden overflow-ellipsis bg-indigo-700 text-white rounded mb-2'>{company.legalForm}</p>
                      <small className='block'>SIREN : {company.siren}</small>
                      <small className='block'>SIRET : {company.siret}</small>
                      <div className='flex align-right justify-between mt-4'>
                        <button onClick={() => {
                          setSelectedCompany(company);
                          setShowModal(true);
                        }} className='flex font-sm bg-gray-800 text-white px-2 py-1 rounded'>
                          <Bars3Icon className='h-5 w-5' />
                          <span className='text-sm'>Détails</span>
                        </button>
                      </div>
                    </div>
                  )
                })
                :
                [1, 2, 3, 4].map((index) => {
                  return <div className="animate-pulse bg-gray-100 shadow-sm rounded-md p-4">
                    <h5 className='py-3 bg-gray-300 rounded mb-2 w-[75%]'></h5>
                    <p className='py-2  bg-gray-300 rounded my-2'></p>
                    <small className='py-1 my-2 block bg-gray-300 w-1/2'></small>
                    <small className='py-1 my-2 block bg-gray-300 w-1/2'></small>
                    <div className='flex align-bottom justify-between mt-4'>
                      <div className='flex font-sm bg-gray-400 text-white px-2 py-1 rounded py-3 px-10'></div>
                      <div className='flex font-sm bg-gray-400 text-white px-2 py-1 rounded py-3 px-10'></div>
                    </div>
                  </div>
                })
            }
          </div> */}
          {/* {(
            companies && companies.length > 0 &&
            <div className="flex justify-center mt-10">
              {[...Array(totalPages).keys()].map(page => (
                <button
                  key={page}
                  onClick={() => handlePageChange(page + 1)}
                  className={`mr-2 px-3 py-1 rounded ${currentPage === page + 1 ? 'bg-gray-300' : 'bg-gray-200'}`}
                >
                  {page + 1}
                </button>
              ))}
            </div>
          )} */}
        </div>

      </main>

      <Modal show={showModal} onClose={() => setShowModal(false)}>
        <Modal.Header className='py-3'>
          <div className='flex justify-between gap-2 items-center'>
            <h5 className='font-bold whitespace-nowrap overflow-hidden overflow-ellipsis'>{selectedCompany.name}</h5>
            <p className='px-2 py-1 m-0 text-xs whitespace-nowrap overflow-hidden overflow-ellipsis bg-indigo-700 text-white rounded'>{selectedCompany.legalForm}</p>

          </div>
        </Modal.Header>
        <Modal.Body className='pt-1'>
          <Tabs aria-label="Default tabs" style="default" theme={{
            tablist: {
              tabitem: {
                base: "flex items-center justify-center px-3 py-2 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none",
                styles: {
                  default: {
                    active: {
                      on: "bg-gray-100 text-indigo-700",
                    }
                  }
                }
              }
            }
          }}>
            <Tabs.Item active title="Identité de l'entreprise">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>SIRET : </span>
                <span>{selectedCompany?.siret}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>SIREN : </span>
                <span>{selectedCompany?.siren}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Date d'immatriculation : </span>
                <span>{moment(selectedCompany?.dateImmatriculation).format('DD/MM/YYYY')}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Début d’activité : </span>
                <span>{moment(selectedCompany?.startDate).format('DD/MM/YYYY')}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Nature de l'entreprise : </span>
                <span>{selectedCompany?.nature}</span>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Activité principale :  </span>
                <span>{selectedCompany?.activity}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Code APE :  </span>
                <span>{selectedCompany?.codeApe}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Capital social :  </span>
                <span>{selectedCompany?.capital} €</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Adresse du siège :  </span>
                <span>{selectedCompany?.address}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Département du siège :  </span>
                <span>{selectedCompany.department}</span>
              </div>

            </Tabs.Item>
            <Tabs.Item title="Informations complémentaires">


              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Convention collective :  </span>
                <span>{selectedCompany.convention}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Nombre de salariés présents :  </span>
                <span>{selectedCompany.nbEmployees}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>URSSAF :  </span>
                <span>{selectedCompany.urssaf}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Caisse de retraite :  </span>
                <span>{selectedCompany.caisseDeRetraite}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Prévoyance :  </span>
                <span>{selectedCompany.prevoyance ? 'Oui' : 'Non'}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Mutuelle :  </span>
                <span>{selectedCompany.mutuelle ? 'Oui' : 'Non'}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>Médecine de travail :  </span>
                <span>{selectedCompany.medecine ? 'Oui' : 'Non'}</span>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 mt-5">
                <span className='font-bold'>OPCO :  </span>
                <span>{selectedCompany.opco}</span>
              </div>
            </Tabs.Item>
            <Tabs.Item title="Gestionnaires">

              <Table>
                <Table.Head>
                  <Table.HeadCell>Nom</Table.HeadCell>
                  <Table.HeadCell>Prénom(s)</Table.HeadCell>
                  <Table.HeadCell>Email</Table.HeadCell>
                  <Table.HeadCell>Tél</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {
                    selectedCompany?.users?.
                      filter(user => !user.roles.includes('ROLE_ADMIN') && !user.roles.includes('ROLE_SUPER_ADMIN')).
                      map((administrators, index) => {
                        return (
                          <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800 p-0">
                            <Table.Cell className='p-0'>
                              <span>{administrators.firstname}</span>
                            </Table.Cell>
                            <Table.Cell>
                              <span>{administrators.lastname}</span>
                            </Table.Cell>
                            <Table.Cell>
                              <span>{administrators.email}</span>
                            </Table.Cell>
                            <Table.Cell>
                              <span>{administrators.tel}</span>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                  }
                </Table.Body>
              </Table>

            </Tabs.Item>

            <Tabs.Item title="Gestionnaires CBS">

              <Table>
                <Table.Head>
                  <Table.HeadCell>Nom</Table.HeadCell>
                  <Table.HeadCell>Prénom(s)</Table.HeadCell>
                  <Table.HeadCell>Email</Table.HeadCell>
                </Table.Head>
                <Table.Body className="divide-y">
                  {
                    selectedCompany?.users?.
                      filter(user => user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_SUPER_ADMIN')).
                      map((administrators, index) => {
                        return (
                          <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800 p-0">
                            <Table.Cell className='p-0'>
                              <span>{administrators.firstname}</span>
                            </Table.Cell>
                            <Table.Cell>
                              <span>{administrators.lastname}</span>
                            </Table.Cell>
                            <Table.Cell>
                              <span>{administrators.email}</span>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                  }
                </Table.Body>
              </Table>

            </Tabs.Item>

          </Tabs>
        </Modal.Body>

        {/* <Modal.Footer className='py-2'>
          <button
            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={() => setShowModal(false)}
          >
            Fermer
          </button>
        </Modal.Footer> */}
      </Modal>


      <Modal show={showAddModal} size={"4xl"} onClose={() => {
        setShowAddModal(false)
        setStep(0)
      }}>
        <Modal.Header>Ajouter une entreprise</Modal.Header>
        <Modal.Body>
          <ol className="items-center w-full space-y-4 sm:flex sm:space-x-8 sm:space-y-0 rtl:space-x-reverse bg-gray-100 py-2 px-3 rounded-xl">
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 0 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 0 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 1 ? <CheckIcon className="h-4 w-4" /> : 1}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">
                  Informations d'entreprise
                </h3>
              </span>
            </li>
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 1 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 1 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 2 ? <CheckIcon className="h-4 w-4" /> : 2}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Informations complémentaires</h3>
              </span>
            </li>
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 2 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 2 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 3 ? <CheckIcon className="h-4 w-4" /> : 3}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Gestionnaires du compte</h3>
              </span>
            </li>
            <li
              className={
                "flex items-center space-x-2.5 rtl:space-x-reverse " +
                (step >= 3 ? "text-indigo-700 " : "text-gray-500 ")
              }
            >
              <span
                className={
                  "flex text-xs items-center justify-center w-5 h-5 border rounded-full shrink-0 " +
                  (step >= 3 ? "border-indigo-700 " : "border-gray-500 ")
                }
              >
                {step >= 4 ? <CheckIcon className="h-4 w-4" /> : 4}
              </span>
              <span>
                <h3 className="font-medium text-sm leading-tight">Gestionnaires CBS</h3>
              </span>
            </li>
          </ol>

          <div className="space-y-6 p-6">
            {step === 0 ?
              companyFound ?
                <div className="mt-3">
                  <form className="space-y-4">
                    {
                      alertMessage &&
                      <Alert color={alertMessage.type}>
                        {alertMessage.message}
                      </Alert>
                    }
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">SIRET</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.siret}
                          onChange={(e) => setCompanyData({ ...companyData, siret: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">SIREN</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.siren}
                          onChange={(e) => setCompanyData({ ...companyData, siren: e.target.value })}
                        />
                      </div>
                    </div>

                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Nom de l'entreprise</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.name}
                          onChange={(e) => setCompanyData({ ...companyData, name: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Nature de l'entreprise</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.nature}
                          onChange={(e) => setCompanyData({ ...companyData, nature: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Forme juridique</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.form}
                          onChange={(e) => setCompanyData({ ...companyData, form: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Capital social</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="number"
                          required
                          value={companyData.capital}
                          onChange={(e) => setCompanyData({ ...companyData, capital: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Activité principale</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.activity}
                          onChange={(e) => setCompanyData({ ...companyData, activity: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Code APE/NAF</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.code_naf}
                          onChange={(e) => setCompanyData({ ...companyData, code_naf: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Date d'immatriculation</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="date"
                          required
                          value={companyData.date_immatriculation}
                          onChange={(e) => setCompanyData({ ...companyData, date_immatriculation: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Début d'activité</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="date"
                          required
                          value={companyData.start_date}
                          onChange={(e) => setCompanyData({ ...companyData, start_date: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Adresse du siège</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.address}
                          onChange={(e) => setCompanyData({ ...companyData, address: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Département du siège</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="number"
                          required
                          value={companyData.department}
                          onChange={(e) => setCompanyData({ ...companyData, department: e.target.value })}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                :
                <>
                  <div className='flex gap-2 w-1/2 mx-auto flex-col content-center text-center justify-center'>
                    <p>Chercher une entreprise par son SIRET</p>
                    <TextInput
                      placeholder='SIRET'
                      type="number"
                      value={companyData.siret}
                      onChange={(e) => setCompanyData({ ...companyData, siret: e.target.value })}
                    />
                    <Button
                      color="white"
                      disabled={companyData?.siret?.length != 14 ? true : false}
                      className="bg-indigo-700 text-white"
                      onClick={() => {
                        fetchCompany()
                      }}
                    >
                      {
                        fetchingCompany ?
                          <div className='flex gap-2 items-center'>
                            <Spinner size="sm" />
                            <span>Recherche en cours</span>
                          </div>
                          :
                          'Rechercher'
                      }
                    </Button>
                    <small className='text-indigo-700 mt-3' role='button' onClick={() => setCompanyFound(true)}>Ajouter une entreprise manuellement</small>
                  </div>
                </>
              : step === 1 ?
                <div className="mt-3">
                  <form className="space-y-4">
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Convention collective </label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          value={companyData.convention}
                          onChange={(e) => setCompanyData({ ...companyData, convention: e.target.value })}
                        />
                      </div>
                      <div className="w-1/2">
                        <label className="block">Nombre de salariés présents</label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="number"
                          required
                          value={companyData.nbEmployees}
                          onChange={(e) => setCompanyData({ ...companyData, nbEmployees: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">URSSAF </label>
                        <select
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          defaultValue={companyData?.urssaf}
                          onChange={(e) => setCompanyData({ ...companyData, urssaf: e.target.value })}
                        >
                          <option value="" disabled selected>Choisir l'URSSAF</option>
                          {
                            listUrssaf.map((item) => (
                              <option key={item.id} value={item.value}>{item.value}</option>
                            ))
                          }
                        </select>
                      </div>
                      <div className="w-1/2">
                        <label className="block">Caisse de retraite </label>
                        <select
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          defaultValue={companyData?.caisseDeRetraite}
                          onChange={(e) => setCompanyData({ ...companyData, caisseDeRetraite: e.target.value })}
                        >
                          <option value="" disabled selected>Choisir la caisse de retraite</option>
                          {
                            listRetraite.map((item) => (
                              <option key={item.id} value={item.value}>{item.value}</option>
                            ))
                          }
                        </select>
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Prévoyance</label>
                        <select
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          defaultValue={companyData?.prevoyance}
                          onChange={(e) => {
                            setCompanyData({
                              ...companyData,
                              prevoyance: e.target.value === 'true' ? true : false,
                            });
                          }
                          }
                        >
                          <option value="" disabled selected>Est-elle mise en place ?</option>
                          <option value={true}>Oui</option>
                          <option value={false}>Non</option>
                        </select>
                      </div>
                      <div className="w-1/2">
                        <label className="block">Mutuelle</label>
                        <select
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          defaultValue={companyData?.mutuelle}
                          onChange={(e) => {
                            setCompanyData({
                              ...companyData,
                              mutuelle: e.target.value === 'true' ? true : false,
                            });
                          }
                          }
                        >
                          <option value="" disabled selected>Est-elle mise en place ?</option>
                          <option value={true}>Oui</option>
                          <option value={false}>Non</option>
                        </select>
                      </div>
                    </div>
                    <div className="w-full flex space-x-4">
                      <div className="w-1/2">
                        <label className="block">Médecine de travail</label>
                        <select
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          defaultValue={companyData?.medecine}
                          onChange={(e) => {
                            setCompanyData({
                              ...companyData,
                              medecine: e.target.value === 'true' ? true : false,
                            });
                          }
                          }
                        >
                          <option value="" disabled selected>Est-elle mise en place ?</option>
                          <option value={true}>Oui</option>
                          <option value={false}>Non</option>
                        </select>
                      </div>
                      <div className="w-1/2">
                        <label className="block">OPCO </label>
                        <input
                          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                          type="text"
                          required
                          // value={companyData.opco}
                          onChange={(e) => setCompanyData({ ...companyData, opco: e.target.value })}
                        />
                      </div>
                    </div>
                  </form>
                </div>
                : step === 2 ? (
                  <div>
                    {
                      alertMessage &&
                      <Alert color={alertMessage.type}>
                        {alertMessage.message}
                      </Alert>
                    }
                    <div className='flex justify-between my-4 items-center'>
                      <small>
                        Pour créer un compte, inserer l'email pour recevoir les informations de connexion
                      </small>
                      <Button
                        color="white"
                        className="bg-white border border-indigo-700 text-indigo-700"
                        size={"xs"}
                        onClick={() => {
                          setAdministrators([...administrators, { firstname: '', lastname: '', email: '' }]);
                        }}
                      >
                        <PlusIcon className='h-5 w-5' />
                        Ajouter
                      </Button>
                    </div>
                    <Tabs.Item title="Dirigeants et associés">
                      <Table>
                        <Table.Head>
                          <Table.HeadCell>Nom</Table.HeadCell>
                          <Table.HeadCell>Prénom(s)</Table.HeadCell>
                          <Table.HeadCell>Email</Table.HeadCell>
                          <Table.HeadCell></Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                          {
                            administrators ?
                              administrators.map((admin, index) => {
                                return (
                                  <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800 p-0">
                                    <Table.Cell className='p-0'>
                                      <TextInput
                                        placeholder='Nom'
                                        sizing={"sm"}
                                        type='text'
                                        value={admin.firstname}
                                        onChange={(e) => {
                                          const updatedAdmins = [...administrators];
                                          updatedAdmins[index].firstname = e.target.value;
                                          setAdministrators(updatedAdmins);
                                        }}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <TextInput
                                        placeholder='Prénom(s)'
                                        sizing={"sm"}
                                        type='text'
                                        value={admin.lastname}
                                        onChange={(e) => {
                                          const updatedAdmins = [...administrators];
                                          updatedAdmins[index].lastname = e.target.value;
                                          setAdministrators(updatedAdmins);
                                        }}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <TextInput
                                        placeholder='Email'
                                        type='email'
                                        sizing={"sm"}
                                        value={admin.email}
                                        onChange={(e) => {
                                          const updatedAdmins = [...administrators];
                                          updatedAdmins[index].email = e.target.value;
                                          setAdministrators(updatedAdmins);
                                        }}
                                      />
                                    </Table.Cell>
                                    <Table.Cell>
                                      <Button
                                        color="white"
                                        className="bg-red-500 text-white"
                                        size={"xs"}
                                        onClick={() => {
                                          const updatedAdministrators = administrators.filter((_, i) => i !== index);
                                          setAdministrators(updatedAdministrators);
                                        }}
                                      >
                                        X
                                      </Button>
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              })

                              : null
                          }
                        </Table.Body>
                      </Table>
                    </Tabs.Item>
                  </div>
                ) : step === 3 ? (
                  <div>
                    {alertMessage && (
                      <Alert color={alertMessage.type}>
                        {alertMessage.message}
                      </Alert>
                    )}
                    <div className='flex justify-between my-4 items-center'>
                      <small>
                        Ajouter les gestionnaires CBS en charge de l'entreprise
                      </small>
                      <Button
                        color="white"
                        className="bg-white border border-indigo-700 text-indigo-700"
                        size={"xs"}
                        onClick={() => {
                          setSelectedManagers([...selectedManagers, { id: '', firstname: '', lastname: '' }]);
                        }}
                      >
                        <PlusIcon className='h-5 w-5' />
                        Ajouter un gestionnaire
                      </Button>
                    </div>
                    <Tabs.Item title="Dirigeants et associés">
                      <Table>
                        <Table.Head>
                          <Table.HeadCell>#</Table.HeadCell>
                          <Table.HeadCell>Nom</Table.HeadCell>
                          <Table.HeadCell>Prénom</Table.HeadCell>
                          <Table.HeadCell>Email</Table.HeadCell>
                          <Table.HeadCell></Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                          {selectedManagers && selectedManagers.map((manager, index) => (
                            <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800 p-0">
                              <Table.Cell className='p-0'>
                                <Select
                                  value={manager.id}
                                  style={{ width: 200 }}
                                  onChange={(value) => {
                                    const selectedManager = managers.find(manager => manager.id === value);
                                    if (selectedManager) {
                                      const updatedManagers = [...selectedManagers];
                                      updatedManagers[index] = selectedManager;
                                      setSelectedManagers(updatedManagers);
                                    }
                                  }}
                                >
                                  {
                                    // disable selected managers from the list
                                    managers.map(mgr => (
                                      <Option key={mgr.id} value={mgr.id}
                                        disabled={selectedManagers.find(selected => selected.id === mgr.id) ? true : false}
                                      >
                                        {mgr.firstname} {mgr.lastname}
                                      </Option>
                                    ))}
                                </Select>
                              </Table.Cell>
                              <Table.Cell>
                                {manager.firstname}
                              </Table.Cell>
                              <Table.Cell>
                                {manager.lastname}
                              </Table.Cell>
                              <Table.Cell>
                                {manager.email}
                              </Table.Cell>
                              <Table.Cell>
                                <Button
                                  color="white"
                                  className="bg-red-500 text-white"
                                  size={"xs"}
                                  onClick={() => {
                                    const updatedManagers = selectedManagers.filter((_, i) => i !== index);
                                    setSelectedManagers(updatedManagers);
                                  }}
                                >
                                  X
                                </Button>
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table>
                    </Tabs.Item>
                  </div>
                )
                  : null}
          </div>
        </Modal.Body>
        <Modal.Footer className="flex justify-between">
          {step > 0 ? (
            <Button
              color="white"
              className="text-indigo-700 border border-indigo-700"
              onClick={() => setStep(step - 1)}
            >
              Précédent
            </Button>
          ) : (
            <Button
              color="white"
              className="text-indigo-700 border border-indigo-700"
              onClick={() => setShowAddModal(false)}
            >
              Fermer
            </Button>
          )}
          {step < 3 ? (
            <Button
              color="white"
              disabled={companyFound ? false : true}
              className="bg-indigo-700 text-white"
              onClick={() => {
                //check if all fields are filled
                if (companyData.siret && companyData.siren && companyData.name && companyData.nature && companyData.form && companyData.capital && companyData.activity && companyData.code_naf && companyData.date_immatriculation && companyData.start_date && companyData.address && companyData.department) {
                  setStep(step + 1);
                  setAlertMessage(null);
                } else {
                  setAlertMessage({
                    type: 'failure',
                    message: 'Veuillez remplir tous les champs'
                  })
                }
              }}
            >
              Suivant
            </Button>
          ) : (
            <Button
              color="white"
              disabled={creatingCompany ? true : false}
              className="bg-green-500 text-white"
              onClick={() => {
                let valid = true;
                administrators.map((admin) => {
                  if (!admin.firstname || !admin.lastname || !admin.email) {
                    valid = false;
                  }
                });
                if (valid) {
                  addCompany();
                } else {
                  setAlertMessage({
                    type: 'failure',
                    message: 'Veuillez remplir tous les informations demandées'
                  })
                }
              }}
            >
              {
                creatingCompany ?
                  <div className='flex gap-2 items-center'>
                    <Spinner size="sm" />
                    <span>Création en cours</span>
                  </div>
                  :
                  <>
                    <CheckCircleIcon className="h-5 w-5 me-2" />
                    Ajouter
                  </>
              }
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div >
  );
}

export default Companies
