import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "./assets/css/styles.css";
// import 'bootstrap/dist/css/bootstrap.min.css';

import Home from "./pages/Home";
import Login from "./pages/Login";
import Companies from "./pages/Companies";
import Employees from "./pages/Employees";
import Comptes from "./pages/Comptes";
import Declarations from "./pages/Declarations";
import { isAuthenticated } from "./utils/Auth";
import Absences from "./pages/Absences";
import Logs from "./pages/Logs";
import { userRequest } from "./makeRequest";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import Variables from "./pages/Variables";
import Profile from "./pages/Profile";
import Settings from "./pages/Settings";
import { checkPageAccess, isAdmin, isSuperAdmin } from "./utils/User";

function App() {

  const privateRoute = (to) => {
    return isAuthenticated() ? (
      to
    ) : (
      <Navigate to="/login" replace />
    );
  };

  const privateAdminRoute = (to) => {
    return isAuthenticated() && (isSuperAdmin() || isAdmin()) ? (
      to
    ) : (
      <Navigate to="/login" replace />
    );
  };

  const privateSuperAdminRoute = (to) => {
    return isAuthenticated() && isSuperAdmin() ? (
      to
    ) : (
      <Navigate to="/login" replace />
    );
  };

  userRequest.interceptors.request.use(async (config) => {
    let token = Cookies.get('token');
    if (token) {
      try {
        let decodedToken = token && jwtDecode(token);
        let currentDate = new Date();
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
          Cookies.remove('token');
          window.location.reload();
        }
      } catch (error) {
        console.error("Erreur lors du décodage du token :", error);
      }
    }
    return config;
  }, (err) => {
    return Promise.reject(err);
  });

  return (
    <BrowserRouter>
      <Routes>
        {/* User Routes */}
        <Route path="/login" element={isAuthenticated() ? <Navigate to="/" replace /> : <Login />} />
        <Route path="/" element={privateRoute(<Home />)} />
        <Route path="/profile" element={privateRoute(<Profile />)} />
        <Route path="/variables" element={privateRoute(checkPageAccess('les_variables_salariales') || checkPageAccess('les_variables_salariales_consulter') ? <Variables /> : <Navigate to="/login" replace />)} />
        <Route path="/employees" element={privateRoute(checkPageAccess('liste_des_salariees') || checkPageAccess('liste_des_salariees_consulter') ? <Employees /> : <Navigate to="/login" replace />)} />
        <Route path="/absences" element={privateRoute(checkPageAccess('gestion_des_absences') || checkPageAccess('gestion_des_absences_consulter') ? <Absences /> : <Navigate to="/login" replace />)} />
        <Route path="/accounts" element={privateRoute(checkPageAccess('gestion_des_comptes') || checkPageAccess('gestion_des_comptes_consulter') ? <Comptes /> : <Navigate to="/login" replace />)} />

        {/* Admin Routes */}
        <Route path="/companies" element={privateAdminRoute(checkPageAccess('liste_des_entreprises') || checkPageAccess('liste_des_entreprises_consulter') ? <Companies /> : <Navigate to="/login" replace />)} />
        <Route path="/declarations" element={privateAdminRoute(checkPageAccess('gestion_des_declaration') || checkPageAccess('gestion_des_declaration_consulter') ? <Declarations /> : <Navigate to="/login" replace />)} />
        <Route path="/logs" element={privateAdminRoute(checkPageAccess('historique_des_activites') || checkPageAccess('historique_des_activites_consulter') ? <Logs /> : <Navigate to="/login" replace />)} />

        {/* Super Admin Routes */}
        <Route path="/settings" element={privateSuperAdminRoute(<Settings />)} />

        {/* Default Route */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;