import { Avatar, Checkbox, message } from "antd";
import { LoadingOutlined } from '@ant-design/icons'
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { UserOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";
import { userRequest } from "../makeRequest";
import { useEffect, useState } from "react";
import { isAdmin, isSuperAdmin } from "../utils/User";

const Profile = () => {

    const token = Cookies.get('token');
    const decodedToken = jwtDecode(token);
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isInfoEdit, setIsInfoEdit] = useState(false);
    const [isPassEdit, setIsPassEdit] = useState(false);
    const [isNotifEdit, setIsNotifEdit] = useState(false);
    const [userNotif, setUserNotif] = useState({});

    const onChange = (e) => {
        const { name, value } = e.target;
        setUser({ ...user, [name]: value });
    }

    const getUserInfo = async () => {
        try {
            const res = await userRequest.get('users/info/me', {
                headers: {
                    "Accept": "application/ld+json",
                    "Content-Type": "application/ld+json"
                }
            });
            if (res.status === 200) {
                setUser(res?.data);
                setUserNotif(res?.data?.notifications);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const { id, ...others } = user;
        try {
            const res = await userRequest.patch(`users/${id}`, { ...others, userNotif })
            if (res.status === 200) {
                await getUserInfo();
                setIsInfoEdit(false);
                setIsPassEdit(false);
                setIsNotifEdit(false);
                message.success('Votre profile a été bien modifier');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        getUserInfo();
    }, []);


    return (
        <div className="relative min-h-screen md:flex">
            <Navbar />
            <Sidebar />
            <main id="content" className="flex-1 p-6 lg:px-8 pt-20 md:pt-6">
                <div className="items-start justify-between md:flex mt-5">
                    <div className="max-w-lg">
                        <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                            Profile
                        </h3>
                        <p className="text-gray-600 mt-2">
                            Page simplifiée pour une gestion efficace et sécurisée de votre Profile.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center py-10 border-b border-gray-900/10">
                    <div className="relative flex flex-col items-center rounded-[20px] w-[350px] sm:w-[500px] mx-auto p-4">
                        <div className="relative flex h-32 w-full justify-center rounded-xl bg-cover">
                            <img
                                src='https://horizon-tailwind-react-git-tailwind-components-horizon-ui.vercel.app/static/media/banner.ef572d78f29b0fee0a09.png'
                                className="absolute flex h-32 w-full justify-center rounded-xl bg-cover"
                                alt="Banner"
                            />
                            <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400">
                                <Avatar icon={<UserOutlined className='text-3xl' />} className='w-full h-full' />
                            </div>
                        </div>
                        <div className="mt-16 flex flex-col items-center">
                            <h4 className="text-xl font-bold text-navy-700">
                                {decodedToken?.fullname}
                            </h4>
                            <p className="text-sm font-normal text-gray-600">{decodedToken?.username}</p>
                        </div>
                    </div>
                </div>
                <div className="mt-5 pb-12 border-b border-gray-900/10">
                    <div className="flex items-center justify-between">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Informations personnelles.</h2>
                        <button
                            onClick={() => setIsInfoEdit(true)}
                            disabled={isInfoEdit}
                            className="cursor-pointer text-sm py-2 px-3 font-medium text-indigo-600 hover:text-indigo-500 duration-150 hover:bg-gray-50 rounded-lg disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-white"
                        >
                            Modifier
                        </button>
                    </div>
                    <div className="max-w-sm mx-auto mt-5">
                        <div>
                            <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">Prénom</label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="firstname"
                                    id="firstname"
                                    value={user?.firstname}
                                    disabled={!isInfoEdit}
                                    onChange={onChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50"
                                />
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">Nom</label>
                            <div className="mt-2">
                                <input
                                    type="text"
                                    name="lastname"
                                    id="lastname"
                                    value={user?.lastname}
                                    disabled={!isInfoEdit}
                                    onChange={onChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50"
                                />
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                            <div className="mt-2">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={user?.email}
                                    disabled={!isInfoEdit}
                                    onChange={onChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50"
                                />
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-900">Téléphone</label>
                            <div className="mt-2">
                                <input
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    value={user?.phone || ""}
                                    disabled={!isInfoEdit}
                                    onChange={onChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 pb-12 border-b border-gray-900/10">
                    <div className="flex items-center justify-between">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Mot de passe.</h2>
                        <button
                            onClick={() => setIsPassEdit(true)}
                            disabled={isPassEdit}
                            className="cursor-pointer text-sm py-2 px-3 font-medium text-indigo-600 hover:text-indigo-500 duration-150 hover:bg-gray-50 rounded-lg disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-white"
                        >
                            Modifier
                        </button>
                    </div>
                    <div className="max-w-sm mx-auto mt-5">
                        <div>
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">Nouveau mot de passe</label>
                            <div className="mt-2">
                                <input
                                    type="password"
                                    name="password"
                                    id="password"
                                    disabled={!isPassEdit}
                                    onChange={onChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50"
                                />
                            </div>
                        </div>
                        <div className="mt-2">
                            <label htmlFor="cPassword" className="block text-sm font-medium leading-6 text-gray-900">Confirmer le nouveau mot de passe</label>
                            <div className="mt-2">
                                <input
                                    type="password"
                                    name="cPassword"
                                    id="cPassword"
                                    disabled={!isPassEdit}
                                    onChange={onChange}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-50"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {
                    isAdmin() || isSuperAdmin() ? (
                        <div className="mt-5 pb-12 border-b border-gray-900/10">
                            <div className="flex items-center justify-between">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Notifications.</h2>
                                <button
                                    onClick={() => setIsNotifEdit(true)}
                                    disabled={isNotifEdit}
                                    className="cursor-pointer text-sm py-2 px-3 font-medium text-indigo-600 hover:text-indigo-500 duration-150 hover:bg-gray-50 rounded-lg disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-white"
                                >
                                    Modifier
                                </button>
                            </div>
                            <div className="mx-auto mt-5">
                                <div>
                                    <div className="mt-2">
                                        <Checkbox checked={userNotif?.notifAbsence} disabled={!isNotifEdit} onChange={(v) => setUserNotif({ ...userNotif, notifAbsence: v.target.checked })}>
                                            Recevoir des notifications pour les déclarations d'absences
                                        </Checkbox>
                                    </div>
                                    <div className="mt-2">
                                        <Checkbox checked={userNotif?.notifEntSort} disabled={!isNotifEdit} onChange={(v) => setUserNotif({ ...userNotif, notifEntSort: v.target.checked })}>
                                            Recevoir des notifications pour les entrées et sorties des salariés
                                        </Checkbox>
                                    </div>
                                    <div className="mt-2">
                                        <Checkbox checked={userNotif?.notifVarSala} disabled={!isNotifEdit} onChange={(v) => setUserNotif({ ...userNotif, notifVarSala: v.target.checked })}>
                                            Recevoir des notifications pour les variables salariales
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                        className="text-sm font-semibold leading-6 text-gray-900"
                        onClick={() => { setIsInfoEdit(false); setIsPassEdit(false); setIsNotifEdit(false); }}
                    >
                        Annuler
                    </button>
                    <button
                        disabled={!isPassEdit && !isInfoEdit && !isNotifEdit}
                        onClick={handleSubmit}
                        className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-50 disabled:text-gray-500 disabled:cursor-not-allowed"
                    >
                        {isLoading && <LoadingOutlined className="mr-2" />}
                        Sauvegarder
                    </button>
                </div>
            </main>
        </div>
    );
};

export default Profile;