import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import Timeline from 'react-calendar-timeline'
import '../assets/css/timeline.css'
import moment from 'moment'
import { Button, Modal, Spinner } from 'flowbite-react';
import Autocomplete from '../components/Autocomplete';
import { AutoComplete, Cascader, Checkbox, ConfigProvider, DatePicker, Image, Input, message, Spin, Upload } from 'antd'
import { userRequest } from '../makeRequest'
import dayjs from 'dayjs'
import fr_FR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr';
import { ArrowLeftCircleIcon, ClipboardDocumentListIcon, DocumentIcon } from '@heroicons/react/24/outline'
import { hasMultipleCompanies, isAdmin } from '../utils/User'
import { Confirmation } from '../components/Confirmation'
import { useLocation } from 'react-router-dom'
dayjs.locale('fr');

const { TextArea } = Input;

const Absences = () => {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [fileList, setFileList] = useState([]);
    const [modal, setOpenModal] = useState(false);
    const [isLoading, setIsLoading] = useState({ loading: false, title: "" });
    const [employees, setEmployees] = useState([]);
    const [absences, setAbsences] = useState([]);
    const [filteredUser, setFilteredUser] = useState(queryParams.get('employee') ? parseInt(queryParams.get('employee')) : null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [companies, setCompanies] = useState(null);
    const [modalData, setModalData] = useState({});
    const [modalVisible, setModalVisible] = useState(false);
    const [absTypes, setAbsTypes] = useState([]);
    const [absenceData, setAbsenceData] = useState({
        employee: "",
        type: "",
        startDate: "",
        endDate: "",
        hoursPerDay: 7,
        status: "",
        informations: ""
    });
    const [oldAbsenceData, setOldAbsenceData] = useState([]);
    const [oldEmployee, setOldEmployee] = useState([]);

    const closeModal = () => {
        setOpenModal(false);
        setAbsenceData({
            employee: "",
            type: "",
            startDate: "",
            endDate: "",
            hoursPerDay: 7,
            status: ""
        });
    };
    const showModal = () => {
        setOpenModal(true);
    };

    useEffect(() => {
        if (isAdmin() || hasMultipleCompanies()) {
            if (queryParams.get('company')) {
                fetchAbsences(parseInt(queryParams.get('company')));
            }
            fetchCompanies();
        } else {
            fetchAbsences(queryParams.get('company') ? parseInt(queryParams.get('company')) : null);
            setSelectedCompany({ id: null, value: null });
        }
    }, []);

    const fetchCompanies = async () => {
        setCompanies(null);
        try {
            const response = await userRequest.get('companies_all');

            if (response.status === 200) {
                const data = response?.data;

                let companyList = [];
                data.forEach((company) => {
                    companyList.push({ id: company.id, value: company.name });
                })
                setCompanies(companyList);
            }
        }
        catch (error) {
            console.error('Error:', error);
        }
    }

    const compressImage = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    let width = img.width;
                    let height = img.height;
                    if (width > height) {
                        if (width > 750) {
                            height *= 750 / width;
                            width = 750;
                        }
                    } else {
                        if (height > 750) {
                            width *= 750 / height;
                            height = 750;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        resolve(blob);
                    }, 'image/jpeg', 0.5);
                };
                img.onerror = (error) => {
                    reject(error);
                };
            };
        });
    };

    const handleFileChange = async (info, type) => {
        if (info?.file?.size / 1024 / 1024 > 2) {
            message.error('La taille du fichier doit être inférieure à 2 Mo');
        } else {
            let newFileList = [...fileList];
            newFileList = newFileList.filter((file) => file.type !== type);

            const compressedFiles = await Promise.all(
                info.fileList.map(async (file) => {
                    if (file.type.includes('image')) {
                        const compressedImage = await compressImage(file.originFileObj);
                        return { ...file, originFileObj: compressedImage, typeofdoc: type };
                    }
                    return { ...file, typeofdoc: type };
                })
            );

            newFileList.push({ type, fileList: compressedFiles });
            console.log(newFileList)
            setFileList(newFileList);
        }
    };

    const fetchAbsences = async (companyId) => {
        setIsLoading({ loading: true, title: "fetchAbsences" });
        try {
            const res = await userRequest.get("absences" + (companyId ? `?company_id=${companyId}` : ""));
            if (res.status === 200) {
                const data = res?.data;
                const updatedEmployees = data?.employees?.map(employee =>
                    ({ ...employee, title: `${employee.firstname} ${employee.lastname}`, value: `${employee.firstname} ${employee.lastname}` })
                );
                const updatedAbsences = (data?.employees?.map(employee => {
                    return employee?.absences?.map(absence =>
                    ({
                        id: absence?.id,
                        group: Number(absence?.employee?.split("/")[3]),
                        title: absence?.type,
                        start_time: moment(moment(absence?.startDate).format('YYYY-MM-DD') + ' 00:00:00'),
                        end_time: moment(moment(absence?.endDate).format('YYYY-MM-DD') + ' 23:59:59'),
                        itemProps: {
                            style: {
                                background: absence?.type == "Congé" ? '#2196f3' : absence?.type == "Rtt" ? '#0e9f6e' : absence?.type == "Maladie" ? '#ff4638' : '#ff9800',
                                border: 'none',
                                borderRadius: 5,
                                fontSize: 10
                            }
                        },
                        informations: absence?.informations,
                    })
                    )
                })).flat();

                setSelectedCompany({ id: data?.company?.id, value: data?.company?.name });
                setEmployees(updatedEmployees);
                setAbsences(updatedAbsences);
                setOldEmployee(updatedEmployees);
                setOldAbsenceData(updatedAbsences);

                const groupedByCategory = data?.absences_types?.reduce((acc, absenceType) => {
                    const { category, name } = absenceType;
                    if (!acc[category]) {
                        acc[category] = [];
                    }
                    acc[category].push({ value: name, label: name });
                    return acc;
                }, {});

                const absTypesOptions = Object.keys(groupedByCategory).map(category => ({
                    value: category,
                    label: category,
                    children: groupedByCategory[category]
                }));

                setAbsTypes(absTypesOptions);

                // setAbsTypes(data?.absences_types);

                if (filteredUser) {
                    setEmployees(updatedEmployees.filter(employee => employee.id === filteredUser));
                }
            }
        }
        catch (error) {
            console.error('Error:', error);
        } finally {
            setIsLoading({ loading: false, title: "" });
        }
    };

    const handleAbsence = async (e) => {
        e.preventDefault();
        setIsLoading({ loading: true, title: "submit" });
        try {
            const formData = new FormData();
            formData.append('company', selectedCompany.id);
            formData.append('employee', absenceData.employee);
            formData.append('type', absenceData.type);
            formData.append('startDate', absenceData.startDate);
            formData.append('endDate', absenceData.endDate);
            formData.append('hoursPerDay', absenceData.hoursPerDay);
            formData.append('status', absenceData.status);
            formData.append('informations', absenceData.informations);
            fileList.forEach((file) => {
                file.fileList.forEach((f) => {
                    formData.append('documents[]', f.originFileObj);
                    formData.append('documents_types[]', f.typeofdoc);
                });
            });

            const res = await userRequest.post("absences", formData, {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Accept': 'application/ld+json',
                }
            });
            if (res.status === 201) {
                setOpenModal(false);
                message.success("Absence ajoutée avec succès");
                await fetchAbsences(selectedCompany.id);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading({ loading: false, title: "" });
        }
    };

    // useEffect(() => {
    //     fetchAbsences();
    // }, []);

    return (
        <div className="relative min-h-screen md:flex" data-dev-hint="container">
            <Navbar />
            <Sidebar />
            <main id="content" className="flex-1 p-6 lg:px-8 pt-20 md:pt-6">
                {
                    selectedCompany && (isAdmin() || hasMultipleCompanies()) ?
                        <div className='text-indigo-700 items-center flex ' role='button' onClick={() => setSelectedCompany(null)}>
                            <ArrowLeftCircleIcon className='h-7 w-7 inline-block me-2' />
                            Retour
                        </div>
                        :
                        null
                }
                <div className="items-start justify-between md:flex">
                    <div className="max-w-lg">
                        <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                            Gestion des Absences
                        </h3>
                        <p className="text-gray-600 mt-2 text-sm">
                            Page simplifiée pour une gestion efficace et sécurisée des absences.
                        </p>
                    </div>
                    {
                        selectedCompany &&
                        <Button onClick={showModal} gradientMonochrome="purple" className="mt-5 md:mt-0">Ajouter</Button>
                    }
                </div>
                {
                    selectedCompany ? <>
                        <div className="max-w-full overflow-hidden">
                            <div className="flex justify-end items-center mt-5 mb-3">
                                <span className="text-sm me-2">Rechercher</span>
                                <Input defaultValue={
                                    filteredUser ? employees.find(employee => employee.id === filteredUser)?.value : ""
                                } type="text" style={{ width: 200 }} className="border border-gray-300 rounded-md px-2 py-1 placeholder-gray-500 placeholder-opacity-25" placeholder="Recherche par nom"
                                    onChange={(e) => {
                                        if (e.target.value === "") {
                                            setEmployees(oldEmployee);
                                            setAbsences(oldAbsenceData);
                                        } else {
                                            const search = e.target.value;
                                            const updatedEmployees = employees.filter(employee => employee.value.toLowerCase().includes(search.toLowerCase()));
                                            setEmployees(updatedEmployees);
                                        }
                                    }}
                                    allowClear={true}
                                />
                            </div>
                            <div className="px-4 sm:px-0">
                                <Spin tip="Chargement..." spinning={isLoading.loading && isLoading.title === "fetchAbsences"}>
                                    {
                                        employees.length > 0 &&

                                        <Timeline
                                            groups={employees}
                                            items={absences}
                                            onItemClick={(itemId) => {
                                                console.log(itemId)
                                                console.log(absences.find(item => item.id === itemId))
                                                setModalData({
                                                    ...absences.find(item => item.id === itemId),
                                                    informations: absences.find(item => item.id === itemId)?.informations,
                                                    employee: employees.find(employee => employee.id === absences.find(item => item.id === itemId).group)
                                                });
                                                console.log(employees.find(employee => employee.id === absences.find(item => item.id === itemId).group))
                                                setModalVisible(true)
                                            }}
                                            stackItems
                                            className='!bg-white'
                                            defaultTimeStart={moment().startOf('month')}
                                            defaultTimeEnd={moment().endOf('month')}
                                        />
                                    }
                                </Spin>
                            </div>
                        </div>
                        <Modal show={modal} onClose={() => { setOpenModal(false); }} className='z-[999]'>
                            <Modal.Header>Ajouter une absence</Modal.Header>
                            <Modal.Body>
                                <div className="space-y-6">
                                    <form>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="employe"
                                            >
                                                Le nom de l'employé
                                            </label>
                                            <AutoComplete
                                                size='large'
                                                className='text-left mx-auto w-full'
                                                options={employees}
                                                // style={{ width: 300 }}
                                                onSelect={(employee, option) => {
                                                    setAbsenceData((prev) => ({ ...prev, employee: option?.id }))
                                                }}
                                                filterOption={(inputValue, option) =>
                                                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                placeholder="Rechercher un employé"
                                            />
                                            {/* <Autocomplete
                                        placeholder="Rechercher un employé"
                                        className="mt-3"
                                        options={employees}
                                        optionLabel="fullname"
                                        onItemSelect={(v) => {
                                            
                                        }}
                                    /> */}
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="dateStart"
                                            >
                                                Date de début
                                            </label>
                                            <ConfigProvider locale={fr_FR}>
                                                <DatePicker
                                                    id="dateStart"
                                                    size='large'
                                                    // value={dayjs(absenceData?.startDate)}
                                                    className='w-full'
                                                    onChange={(date, dateString) => setAbsenceData({ ...absenceData, startDate: dateString })}
                                                />
                                            </ConfigProvider>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="dateEnd"
                                            >
                                                Date de fin
                                            </label>
                                            <ConfigProvider locale={fr_FR}>
                                                <DatePicker
                                                    id="dateEnd"
                                                    size='large'
                                                    // value={dayjs(absenceData?.endDate)}
                                                    className='w-full'
                                                    onChange={(date, dateString) => setAbsenceData({ ...absenceData, endDate: dateString })}
                                                />
                                            </ConfigProvider>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="roles"
                                            >
                                                Nombre d'heures
                                            </label>
                                            <div className='flex gap-2 items-center'>
                                                <Checkbox checked={absenceData?.hoursPerDay == 7} onChange={(e) => setAbsenceData({ ...absenceData, hoursPerDay: e.target.checked ? 7 : 0 })}>Toute la journée (7 heures)</Checkbox>
                                                {
                                                    absenceData?.hoursPerDay != 7 &&
                                                    <Input size='small' type='number' placeholder="Entrez le nombre d'heures" value={absenceData?.hoursPerDay} onChange={(v) => setAbsenceData({ ...absenceData, hoursPerDay: v.currentTarget.value })} className='w-[250px] rounded-md border-gray-300 shadow-sm' />
                                                }
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="roles"
                                            >
                                                Type
                                            </label>
                                            {/* <select
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                id="type"
                                                onChange={(e) => setAbsenceData({ ...absenceData, type: e.target.value })}
                                            >
                                                <option value="" disabled selected>Selectionner un type</option>
                                                {
                                                    absTypes.map((type) => {
                                                        return (
                                                            <option key={type.id} value={type.name}>{type.name}</option>
                                                        );
                                                    })
                                                }
                                            </select> */}
                                            <Cascader
                                                size='large'
                                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                                                options={absTypes} onChange={(e, v) => {
                                                    setAbsenceData({ ...absenceData, type: e[1] })
                                                }} placeholder="Selectionner un type d'absence" />
                                        </div>
                                        <div className="mb-4">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="roles"
                                            >
                                                Informations complémentaires (optionnel)
                                            </label>
                                            <TextArea className='w-full rounded-md border-gray-300 shadow-sm' value={absenceData.informations} onChange={(v) => {
                                                setAbsenceData({ ...absenceData, informations: v.currentTarget.value })
                                            }} />
                                        </div>
                                        <div className="">
                                            <label
                                                className="block text-gray-700 text-sm font-bold mb-2"
                                                htmlFor="roles"
                                            >
                                                Documents (optionnel)
                                            </label>
                                            <div className=' p-3 rounded-xl border-dashed border-2 relative'>
                                                <Upload
                                                    listType='picture'
                                                    onPreview={(e) => {
                                                        window.open(URL.createObjectURL(e.originFileObj));
                                                    }}
                                                    accept=".pdf, .png, .jpg, .jpeg"
                                                    fileList={fileList.find(file => file.type === 'absence_' + absenceData?.startDate + '_' + absenceData?.endDate) ? fileList.find(file => file.type === 'absence_' + absenceData?.startDate + '_' + absenceData?.endDate).fileList : []}
                                                    onChange={info => handleFileChange(info, 'absence_' + absenceData?.startDate + '_' + absenceData?.endDate)}
                                                    beforeUpload={() => false}
                                                    showUploadList={true}
                                                >
                                                    <button onClick={(e) => { e.preventDefault() }} className="border border-green-500 text-xs text-green-600 px-2 py-1 rounded absolute top-3 right-2">Télécharger</button>
                                                </Upload>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </Modal.Body>
                            <Modal.Footer className="flex justify-end">
                                <Button onClick={closeModal} color="gray">Annuler</Button>
                                <Button onClick={handleAbsence} gradientMonochrome="purple">
                                    {(isLoading.loading && isLoading.title === "submit") && <Spinner color="purple" aria-label="submit spinner" size="sm" className="mr-2" />}
                                    Ajouter
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </> :
                        <div className="flex justify-center items-center mt-20">
                            <div className="bg-gray-50 shadow-xs p-6 rounded-lg text-center">
                                <svg height={100} className='mx-auto mb-3' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M5.87868 2.87868C5 3.75736 5 5.17157 5 8V16C5 18.8284 5 20.2426 5.87868 21.1213C6.75736 22 8.17157 22 11 22H13C15.8284 22 17.2426 22 18.1213 21.1213C19 20.2426 19 18.8284 19 16V8C19 5.17157 19 3.75736 18.1213 2.87868C17.2426 2 15.8284 2 13 2H11C8.17157 2 6.75736 2 5.87868 2.87868ZM8.25 17C8.25 16.5858 8.58579 16.25 9 16.25H12C12.4142 16.25 12.75 16.5858 12.75 17C12.75 17.4142 12.4142 17.75 12 17.75H9C8.58579 17.75 8.25 17.4142 8.25 17ZM9 12.25C8.58579 12.25 8.25 12.5858 8.25 13C8.25 13.4142 8.58579 13.75 9 13.75H15C15.4142 13.75 15.75 13.4142 15.75 13C15.75 12.5858 15.4142 12.25 15 12.25H9ZM8.25 9C8.25 8.58579 8.58579 8.25 9 8.25H15C15.4142 8.25 15.75 8.58579 15.75 9C15.75 9.41421 15.4142 9.75 15 9.75H9C8.58579 9.75 8.25 9.41421 8.25 9Z" fill="#1C274C"></path> <path opacity="0.5" d="M5.23525 4.05811C5 4.94139 5 6.17689 5 7.99985V15.9999C5 17.8229 5 19.0584 5.23527 19.9417L5 19.9238C4.02491 19.8279 3.36857 19.6111 2.87868 19.1212C2 18.2425 2 16.8283 2 13.9999V9.99991C2 7.17148 2 5.75726 2.87868 4.87859C3.36857 4.3887 4.02491 4.17194 5 4.07602L5.23525 4.05811Z" fill="#1C274C"></path> <path opacity="0.5" d="M18.7646 19.9417C18.9999 19.0584 18.9999 17.8229 18.9999 15.9999V7.99985C18.9999 6.17689 18.9999 4.94139 18.7647 4.05811L18.9999 4.07602C19.975 4.17194 20.6314 4.3887 21.1212 4.87859C21.9999 5.75726 21.9999 7.17148 21.9999 9.99991V13.9999C21.9999 16.8283 21.9999 18.2425 21.1212 19.1212C20.6314 19.6111 19.975 19.8279 18.9999 19.9238L18.7646 19.9417Z" fill="#1C274C"></path> </g></svg>
                                <p className="text-2xl">Les absences</p>
                                <small>
                                    Pour voir la liste des absences, veuillez sélectionner une entreprise
                                </small>
                                <div className='text-center mt-3'>
                                    <AutoComplete
                                        size='large'
                                        className='text-left mx-auto'
                                        options={companies}
                                        style={{ width: 300 }}
                                        onSelect={(company, option) => {
                                            setSelectedCompany(option);
                                            fetchAbsences(option.id);
                                        }}
                                        filterOption={(inputValue, option) =>
                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                        }
                                        placeholder="Rechercher une entreprise"
                                    />
                                </div>
                            </div>
                        </div>
                }
            </main>
            <Modal show={modalVisible} onClose={() => setModalVisible(false)} className='z-[999]'>
                <Modal.Header>{modalData?.employee?.title}</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="dateStart"
                            >
                                Date de début
                            </label>
                            <p>{moment(modalData?.start_time).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="dateEnd"
                            >
                                Date de fin
                            </label>
                            <p>{moment(modalData?.end_time).format('DD/MM/YYYY')}</p>
                        </div>
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="roles"
                            >
                                Type
                            </label>
                            <p>{modalData?.title}</p>
                        </div>
                        <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="roles"
                            >
                                Informations complémentaires
                            </label>
                            <p>{modalData?.informations || '-'}</p>
                        </div>
                        <div className="">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="roles"
                            >
                                Documents
                            </label>
                            <div className=' p-3 rounded-xl border-dashed border-2 relative'>
                                <div className="flex space-x-2 mt-3 ">
                                    {
                                        modalData?.employee?.documents
                                            ?.filter((document) => document.fileType === 'absence_' + moment(modalData?.start_time).format('YYYY-MM-DD') + '_' + moment(modalData?.end_time).format('YYYY-MM-DD'))
                                            ?.map((document) => {
                                                return (
                                                    document.fileName.includes('.pdf') ?
                                                        <a href={`http://social.sascbs.com/v1/public/documents/${document.fileNameHash}`} target="_blank" className="text-blue-500 underline">
                                                            <DocumentIcon className="h-20 h-20" />
                                                        </a>
                                                        :
                                                        <Image height={80} width={80} className="h-5 w-5" src={`http://social.sascbs.com/v1/public/documents/${document.fileNameHash}`} />
                                                );
                                            }
                                            )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {
                    isAdmin() &&
                    <Modal.Footer className="flex justify-end">
                        <button className="bg-green-500 text-white px-2 py-1 rounded-md hover:bg-green-600" onClick={() => {
                            Confirmation({
                                title: 'Marquer comme traité',
                                content: 'Voulez-vous vraiment marquer cette déclaration comme traitée ?',
                                onConfirm: async () => {
                                    try {
                                        await userRequest.put(`declaration/absence/${modalData?.employee?.id}/${moment(modalData?.start_time).format('MM-YYYY')}/treat`);
                                        await fetchAbsences(selectedCompany.id);
                                        setModalVisible(false);
                                    }
                                    catch (error) {
                                        console.error('Error:', error);
                                    }
                                }
                            })
                        }}>Marquer comme traité</button>
                    </Modal.Footer>
                }
            </Modal>
        </div>
    )
}

export default Absences