import { Tabs, TextInput } from "flowbite-react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { Table, Checkbox, message, ConfigProvider, Divider, Space, Input, Select } from "antd";
import { useEffect, useRef, useState } from "react";
import { userRequest } from "../makeRequest";
import dayjs from 'dayjs';
import fr_FR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr';
dayjs.locale('fr');

let index = 0;

const Settings = () => {
    const [absencesTypes, setAbsencesTypes] = useState(null);
    const [editingKey, setEditingKey] = useState('');
    const [editingType, setEditingType] = useState(null);
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState('');

    const inputRef = useRef(null);

    const onNameChange = (event) => {
        setCategory(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        setCategories([...categories, category]);
        setCategory('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };

    useEffect(() => {
        fetchAbsencesTypes();
    }, []);

    const fetchAbsencesTypes = async () => {
        setCategories([]);
        setAbsencesTypes([]);
        try {
            const response = await userRequest.get('/type_absences', {
                headers: {
                    'Content-Type': 'application/ld+json',
                    'Accept': 'application/ld+json',
                }
            });

            //order by category
            setAbsencesTypes(response.data['hydra:member'].sort((a, b) => (a.category > b.category) ? 1 : -1));

            const categories = response.data['hydra:member']
                .map(type => type.category)
                .filter(category => category !== "");

            setCategories([...new Set(categories)]);


        } catch (error) {
            message.error("Erreur lors de la récupération des types d'absences");
            setAbsencesTypes([]);
        }
    }

    const handleAdd = () => {
        setEditingType({ name: '', status: true });
        setEditingKey('new');
    };

    const handleEdit = (record) => {
        setEditingType({ ...record });
        setEditingKey(record.key);
    };

    const handleSave = async () => {
        if (editingKey === 'new') {
            try {
                const response = await userRequest.post('/type_absences', editingType, {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        'Accept': 'application/ld+json',
                    }
                });
                message.success("Type d'absence ajouté avec succès");
                setAbsencesTypes([...absencesTypes, { ...editingType, id: response.data.id }]);
                setEditingKey('');
                fetchAbsencesTypes();
            } catch (error) {
                message.error("Erreur lors de l'ajout du type d'absence");
            }
        } else {
            try {
                await userRequest.put(`/type_absences/${editingType.id}`, editingType, {
                    headers: {
                        'Content-Type': 'application/ld+json',
                        'Accept': 'application/ld+json',
                    }
                });
                message.success("Type d'absence mis à jour avec succès");
                setAbsencesTypes(absencesTypes.map(type => type.id === editingType.id ? editingType : type));
                setEditingKey('');
                fetchAbsencesTypes();
            } catch (error) {
                message.error("Erreur lors de la mise à jour du type d'absence");
            }
        }
    };

    const handleCancel = () => {
        setEditingKey('');
        setEditingType(null);
    };

    const columns = [
        {
            title: 'Rubrique',
            dataIndex: 'category',
            key: 'category',
            render: (text, record) => (
                editingKey === record.key ? (
                    // <TextInput
                    //     value={editingType.category}
                    //     onChange={(e) => setEditingType({ ...editingType, category: e.target.value })}
                    // />
                    <Select
                        style={{
                            width: 300,
                        }}
                        defaultValue={editingType.category}
                        onChange={(value) => setEditingType({ ...editingType, category: value })}
                        placeholder="Sélectionner une rubrique"
                        dropdownRender={(menu) => (
                            <>
                                {menu}
                                <Divider
                                    style={{
                                        margin: '8px 0',
                                    }}
                                />
                                <Space
                                    style={{
                                        padding: '0 8px 4px',
                                    }}
                                >
                                    <TextInput
                                        placeholder="Le nom de la rubrique"
                                        ref={inputRef}
                                        value={category}
                                        onChange={onNameChange}
                                        onKeyDown={(e) => e.stopPropagation()}
                                    />
                                    <button type="text" onClick={addItem} className="bg-green-500 text-white font-semibold py-2 px-3 rounded-lg border-0">
                                        Ajouter
                                    </button>
                                </Space>
                            </>
                        )}
                        options={categories.map((item) => ({
                            label: item,
                            value: item,
                        }))}
                    />
                ) : (
                    text
                )
            ),
        },
        {
            title: 'Sous-rubrique',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                editingKey === record.key ? (
                    <TextInput
                        value={editingType.name}
                        onChange={(e) => setEditingType({ ...editingType, name: e.target.value })}
                    />
                ) : (
                    text
                )
            ),
        },
        {
            title: 'Statut',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => (
                editingKey === record.key ? (
                    <Checkbox
                        checked={editingType.status}
                        onChange={(e) => setEditingType({ ...editingType, status: e.target.checked })}
                    >
                        Actif
                    </Checkbox>
                ) : (
                    <span className={`px-2 py-1 rounded-full text-xs ${record.status ? 'bg-green-200 text-green-700' : 'bg-red-200 text-red-700'}`}>
                        {record.status ? 'Actif' : 'Inactif'}
                    </span>
                )
            ),
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                editingKey === record.key ? (
                    <>
                        <button type="primary" onClick={handleSave} className="bg-green-500 text-white font-semibold py-1 px-3 rounded-lg">Enregistrer</button>
                        <button onClick={handleCancel} className="ml-2 bg-gray-100 text-gray-500 font-semibold py-1 px-3 rounded-lg">Annuler</button>
                    </>
                ) : (
                    <>
                        <button onClick={() => handleEdit(record)} className="bg-green-500 text-white font-semibold py-1 px-3 rounded-lg">Modifier</button>
                        <button onClick={() => {
                            userRequest.delete(`/type_absences/${record.id}`, {
                                headers: {
                                    'Content-Type': 'application/ld+json',
                                    'Accept': 'application/ld+json',
                                }
                            });
                            setAbsencesTypes(absencesTypes.filter(type => type.id !== record.id));
                            message.success("Type d'absence supprimé avec succès");
                        }} className="ml-2 bg-red-500 text-white font-semibold py-1 px-3 rounded-lg">Supprimer</button>
                    </>
                )
            ),
        },
    ];

    const dataSource = absencesTypes ? absencesTypes.map((type, index) => ({ ...type, key: index })) : [];
    if (editingKey === 'new') {
        dataSource.push({ ...editingType, key: 'new' });
    }

    return (
        <div className="relative min-h-screen md:flex">
            <Navbar />
            <Sidebar />
            <main id="content" className="flex-1 p-6 lg:px-8 pt-20 md:pt-6">
                <div className="items-start justify-between md:flex mt-5">
                    <div className="max-w-lg">
                        <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
                            Paramètres de l'application
                        </h3>
                        <p className="text-gray-600 mt-2">
                            Gérer l'ensemble des paramètres de la plateforme.
                        </p>
                    </div>
                </div>

                <Tabs className="mt-5" aria-label="Default tabs" theme={{
                    tablist: {
                        tabitem: {
                            base: "flex items-center justify-center px-3 py-2 rounded-t-lg text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none",
                            styles: {
                                default: {
                                    active: {
                                        on: "bg-gray-100 text-indigo-700",
                                    }
                                }
                            }
                        }
                    }
                }}>
                    <Tabs.Item title="Types d'absences" className="">
                        <div className="px-2 px-md-5">
                            <div className="mt-5">
                                <button
                                    onClick={handleAdd}
                                    className="my-5 float-right bg-indigo-700 text-white font-semibold py-2 px-4 rounded-lg">
                                    Ajouter un nouveau type
                                </button>
                            </div>
                            <ConfigProvider locale={fr_FR}>
                                <Table
                                    className=""
                                    columns={columns}
                                    loading={absencesTypes === null}
                                    dataSource={dataSource}
                                />
                            </ConfigProvider>
                        </div>
                    </Tabs.Item>
                </Tabs>
            </main>
        </div>
    );
};

export default Settings;
