import React, { useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar'
import { ClipboardDocumentListIcon, UsersIcon, DocumentIcon, BuildingOfficeIcon, ArrowUpOnSquareStackIcon, ArrowDownOnSquareStackIcon, DocumentCheckIcon } from "@heroicons/react/24/outline";
import { hasMultipleCompanies, isAdmin } from '../utils/User';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { AutoComplete, ConfigProvider, DatePicker } from 'antd';
import { userRequest } from '../makeRequest';
import dayjs from 'dayjs';
import fr_FR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr';
dayjs.locale('fr');

const Home = () => {
  const [employees, setEmployees] = useState([]);
  const [dashboardData, setDashboardData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [month, setMonth] = useState(dayjs().format("YYYY-MM"));
  const [selectedCompany, setSelectedCompany] = useState(null);
  const navigate = useNavigate();

  const fetchDashboardData = async (company) => {
    try {
      const reponse = await userRequest.get(`dashboard?month=${month}&company_id=${company}`);
      if (reponse.status === 200) {
        const data = reponse.data;
        setDashboardData(data);
        setEmployees(data['lastEmployees']);
        let companies = data['listCompanies']?.map(company => {
          return {
            id: company['id'],
            value: company['name']
          }
        });
        setCompanies(companies);
      }
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    fetchDashboardData("");
  }, []);

  useEffect(() => {
    fetchDashboardData(selectedCompany);
  }, [month, selectedCompany]);

  return (
    <div className="relative min-h-screen md:flex" data-dev-hint="container">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8 pt-20 md:pt-6">
        <div className="flex-wrap items-center justify-between sm:flex">
          <div className="w-1/2">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              Bienvenue sur votre tableau de bord
            </h3>
            <p className="text-gray-600 mt-2 text-sm">
              Ici vous pouvez consulter l'ensemble des statistiques
            </p>
          </div>
          <div className="w-1/2">
            <div className="flex items-center justify-between gap-2">
              <div className="w-1/2">
                {
                  hasMultipleCompanies() ?
                    <AutoComplete
                      size='large'
                      className="w-full"
                      allowClear={true}
                      placeholder="Rechercher une entreprise"
                      options={companies}
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                      onClear={() => {
                        fetchDashboardData("");
                        setSelectedCompany(null);
                      }}
                      onSelect={(_, company) => {
                        setSelectedCompany(company.id);
                        fetchDashboardData(company.id);
                      }}
                    />
                    :
                    null
                }
              </div>
              <div className="w-1/2">
                <ConfigProvider locale={fr_FR}>
                  <DatePicker
                    size='large'
                    className='w-full'
                    allowClear={false}
                    picker='month'
                    value={dayjs(month)}
                    onChange={(date) => {
                      setMonth(date.format("YYYY-MM"));
                    }}
                    format={"YYYY-MM"}
                  />
                </ConfigProvider>
                {/* <RangePicker
                  size='large'
                  allowClear={false}
                  defaultValue={[dayjs(dates.from), dayjs(dates.to)]}
                  onChange={(dates) => {
                    setDates({
                      from: dates[0].format("YYYY-MM-DD"),
                      to: dates[1].format("YYYY-MM-DD"),
                    });
                  }}
                  format={"YYYY-MM-DD"}
                /> */}
              </div>
            </div>
          </div>
          {/* <Button onClick={showModal} gradientMonochrome="purple" className="mt-5 md:mt-0">Ajouter</Button> */}
        </div>
        <div className="max-w-7xl mx-auto mt-5">
          <div className="px-4 py-6 sm:px-0">
            {
              isAdmin() ?
                <div className="flex-wrap items-center justify-between text-center sm:flex">
                  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-2" role='button' onClick={() => navigate('/companies')}>
                    <div className='p-4 bg-white shadow-md rounded-2xl my-2'>
                      <div className="flex items-center">
                        <BuildingOfficeIcon className="h-5 w-5 text-green-500 " />
                        <p className="ml-2 text-xs text-gray-700 text-md">
                          Nombre d'entreprises
                        </p>
                      </div>
                      <div className="flex flex-col justify-start">
                        <p className="my-4 text-3xl font-bold text-left text-gray-800">
                          {dashboardData?.countCompanies || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-2" role='button' onClick={() => navigate('/employees')}>
                    <div className='p-4 bg-white shadow-md rounded-2xl my-2'>
                      <div className="flex items-center">
                        <UsersIcon className="h-5 w-5 text-green-500 " />
                        <p className="ml-2 text-xs text-gray-700 text-md">
                          Nombre de salariées
                        </p>
                      </div>
                      <div className="flex flex-col justify-start">
                        <p className="my-4 text-3xl font-bold text-left text-gray-800">
                          {dashboardData?.countEmployees || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-2" role='button' onClick={() => navigate('/declarations?status=PENDING')}>
                    <div className='p-4 bg-white shadow-md rounded-2xl my-2'>
                      <div className="flex items-center">
                        <DocumentIcon className="h-5 w-5 text-green-500 " />
                        <p className="ml-2 text-xs text-gray-700 text-md">
                          Déclarations à traiter
                          {/* <small className='ms-1'>ce mois-ci</small> */}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="my-4 text-3xl font-bold text-left text-gray-800">
                          {dashboardData?.declarations_pending || 0}
                        </p>
                        {
                          dashboardData?.late_declarations > 0 &&
                          <small className='bg-red-500 text-[11px] text-white rounded px-2'>{dashboardData?.late_declarations} en retard </small>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-2" role='button' onClick={() => navigate('/declarations?status=TREATED')}>
                    <div className='p-4 bg-white shadow-md rounded-2xl my-2'>
                      <div className="flex items-center">
                        <DocumentCheckIcon className="h-5 w-5 text-green-500 " />
                        <p className="ml-2 text-xs text-gray-700 text-md">
                          Déclarations traité
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="my-4 text-3xl font-bold text-left text-gray-800">
                          {dashboardData?.declarations_treated || 0}
                        </p>
                        {
                          dashboardData?.late_treated_declarations > 0 &&
                          <small className='bg-red-500 text-[11px] text-white rounded px-2'>{dashboardData?.late_treated_declarations} en retard </small>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                :
                <div className="flex-wrap items-center justify-between text-center sm:flex">
                  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-2">
                    <div className='p-4 bg-white shadow-md rounded-2xl my-2'>
                      <div className="flex items-center">
                        <UsersIcon className="h-5 w-5 text-green-500 " />
                        <p className="ml-2 text-xs text-gray-700 text-md">
                          Nombre de salariées
                        </p>
                      </div>
                      <div className="flex flex-col justify-start">
                        <p className="my-4 text-3xl font-bold text-left text-gray-800">
                          {dashboardData?.countEmployees || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-2">
                    <div className='p-4 bg-white shadow-md rounded-2xl my-2'>
                      <div className="flex items-center">
                        <ArrowDownOnSquareStackIcon className="h-5 w-5 text-green-500 " />
                        <p className="ml-2 text-xs text-gray-700 text-md">
                          Nombre d'entrées déclarées
                          {/* <small className='ms-1'>ce mois-ci</small> */}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="my-4 text-3xl font-bold text-left text-gray-800">
                          {dashboardData?.countEntries || 0}
                        </p>
                        {/* {
                          dashboardData?.countLateDeclarations > 0 &&
                          <small className='bg-red-500 text-[11px] text-white rounded px-2'>{dashboardData?.countLateDeclarations} en retard le mois dérnier</small>
                        } */}
                      </div>

                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-2">
                    <div className='p-4 bg-white shadow-md rounded-2xl my-2'>
                      <div className="flex items-center">
                        <ArrowUpOnSquareStackIcon className="h-5 w-5 text-green-500 " />
                        <p className="ml-2 text-xs text-gray-700 text-md">
                          Nombre de sorties déclarées
                          {/* <small className='ms-1'>ce mois-ci</small> */}
                        </p>
                      </div>
                      <div className="flex flex-col justify-start">
                        <p className="my-4 text-3xl font-bold text-left text-gray-800">
                          {dashboardData?.countDepartures || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 px-2">
                    <div className='p-4 bg-white shadow-md rounded-2xl my-2'>
                      <div className="flex items-center">
                        <ClipboardDocumentListIcon className="h-5 w-5 text-green-500 " />
                        <p className="ml-2 text-xs text-gray-700 text-md">
                          Nombre d'absences déclarées
                          {/* <small className='ms-1'>ce mois-ci</small> */}
                        </p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="my-4 text-3xl font-bold text-left text-gray-800">
                          {dashboardData?.countAbsences || 0}
                        </p>
                      </div>

                    </div>
                  </div>
                </div>
            }


            {
              isAdmin() ?
                <>
                  <div className='flex flex-wrap mt-8'>
                    <div className="container md:w-100 lg:w-1/2 sm:px-2 my-2">
                      <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                        <div className='flex justify-between bg-gray-900 text-white p-3 rounded-t-xl'>
                          <h3 className=''>Les déclarations des entreprises</h3>
                          <button className='bg-gray-700 px-2 rounded-md' onClick={() => navigate('/declarations')}>Voir plus</button>
                        </div>
                        <div className="inline-block min-w-full overflow-hidden rounded-lg border">
                          <table className="min-w-full leading-normal">
                            <thead>
                              <tr>
                                <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                  Entreprise
                                </th>
                                <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                  En attente
                                </th>
                                <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                  Traitées
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                dashboardData?.companies_declarations?.slice(0, 5)?.map((company, index) => {
                                  return (
                                    <tr role='button' key={index} onClick={() => navigate('/declarations?company=' + company['id'])}>
                                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                          {company['company']}
                                        </p>
                                      </td>
                                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                          {company['pendingCount']}
                                        </p>
                                      </td>
                                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                          {company['treatedCount']}
                                        </p>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="container md:w-100 lg:w-1/2 sm:px-2 my-2">
                      <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                        <div className='flex justify-between bg-gray-900 text-white p-3 rounded-t-xl'>
                          <h3 className=''>Dernières entreprises</h3>
                          <button className='bg-gray-700 px-2 rounded-md' onClick={() => navigate('/companies')}>Voir plus</button>
                        </div>
                        <div className="inline-block min-w-full overflow-hidden rounded-lg border">
                          <table className="min-w-full leading-normal">
                            <thead>
                              <tr>
                                <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                  Nom de l'entreprise
                                </th>
                                <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                  SIRET
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                dashboardData?.lastCompanies?.slice(0, 5)?.map((company, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                          {company['name']}
                                        </p>
                                      </td>
                                      <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                        <p className="text-gray-900 whitespace-no-wrap">
                                          {company['siret']}
                                        </p>
                                      </td>
                                    </tr>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <div className='flex flex-wrap mt-8'>
                  <div className="container md:w-100 sm:px-2 my-2">
                    <div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
                      <div className='flex justify-between bg-gray-900 text-white p-3 rounded-t-xl'>
                        <h3 className=''>Les dernières salariés {hasMultipleCompanies() ? 'de vos entreprises' : ''}</h3>
                        <button className='bg-gray-700 px-2 rounded-md' onClick={() => navigate('/employees')}>Voir plus</button>
                      </div>
                      <div className="inline-block min-w-full overflow-hidden rounded-lg border">
                        <table className="min-w-full leading-normal">
                          <thead>
                            <tr>
                              <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Nom
                              </th>
                              <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Prénom(s)
                              </th>
                              <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Salaire
                              </th>
                              <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Contrat
                              </th>
                              <th scope="col" className="px-5 py-3 text-sm font-normal text-left text-gray-800 uppercase bg-white border-b border-gray-200">
                                Date d'entrée
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              employees?.slice(0, 5)?.map((employee, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                      <p className="text-gray-900 whitespace-no-wrap">
                                        {employee['lastname']}
                                      </p>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                      <p className="text-gray-900 whitespace-no-wrap">
                                        {employee['firstname']}
                                      </p>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                      <p className="text-gray-900 whitespace-no-wrap">
                                        {employee['salary']}€
                                      </p>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                      <p className="text-gray-900 whitespace-no-wrap">
                                        <span className='bg-indigo-700 text-white px-2 py-1 rounded uppercase'>{employee['contract']}</span>
                                      </p>
                                    </td>
                                    <td className="px-5 py-5 text-sm bg-white border-b border-gray-200">
                                      <p className="text-gray-900 whitespace-no-wrap">
                                        {moment(employee['createdAt']).format('DD/MM/YYYY')}
                                      </p>
                                    </td>
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
            }

          </div>
        </div>
      </main>
    </div>
  );
}

export default Home
