import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useEffect, useState } from "react";
import { Table, DatePicker, AutoComplete, Tag, ConfigProvider } from "antd";
import { userRequest } from "../makeRequest";
import moment from "moment";
import { Confirmation } from "../components/Confirmation";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import fr_FR from 'antd/locale/fr_FR';

import 'dayjs/locale/fr';
dayjs.locale('fr');

const Declarations = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [declarations, setDeclarations] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [filter, setFilter] = useState({
    month: null,
    company: queryParams.get('company') ? parseInt(queryParams.get('company')) : null,
    status: queryParams.get('status') ? queryParams.get('status') : null,
    type: null
  });


  useEffect(() => {
    fetchCompanies();
    fetchDeclarations();
  }, []);

  const fetchCompanies = async () => {
    setCompanies(null);
    try {
      const response = await userRequest.get('companies_all');
      let companyList = [];
      response.data.forEach((company) => {
        companyList.push({ id: company.id, value: company.name });
      })
      setCompanies(companyList);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  const fetchDeclarations = async () => {

    setDeclarations(null);
    try {
      const response = await userRequest.get(`declarations?${filter.month ? 'month=' + filter.month : ''}${filter.company ? '&company=' + filter.company : ''}${filter.status ? '&status=' + filter.status : ''}${filter.type ? '&type=' + filter.type : ''}`);
      console.log(response.data)
      setDeclarations(response.data);
    }
    catch (error) {
      console.error('Error:', error);
    }
  }

  useEffect(() => {
    fetchDeclarations();
  }, [filter]);

  return (
    <div className="relative min-h-screen md:flex">
      <Navbar />
      <Sidebar />
      <main id="content" className="flex-1 p-6 lg:px-8 pt-20 md:pt-6">
        <div className="flex-wrap items-center justify-between sm:flex">
          <div className="w-1/2">
            <h3 className="text-gray-800 text-xl font-bold sm:text-2xl">
              Gestions des déclarations
            </h3>
            <p className="text-gray-600 mt-2 text-sm">
              Gérez les déclarations de vos entreprises
            </p>
          </div>
          <div className="w-1/2">
            <div className="flex items-center justify-between gap-2">
              <div className="w-1/2">
                {
                  <AutoComplete
                    size='large'
                    className="w-full"
                    allowClear={true}
                    value={filter.company ? companies?.find(company => company.id === filter.company)?.value : null}
                    placeholder="Rechercher une entreprise"
                    options={companies}
                    filterOption={(inputValue, option) =>
                      option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                    }
                    onClear={() => {
                      setFilter({ ...filter, company: null });
                    }}
                    onSelect={(_, company) => {
                      setFilter({ ...filter, company: company.id });
                    }}
                  />
                }
              </div>
              <div className="w-1/2">
                <ConfigProvider locale={fr_FR}>
                  <DatePicker
                    size='large'
                    className='w-full'
                    allowClear={false}
                    picker='month'
                    // value={dayjs(month)}
                    defaultValue={dayjs()}
                    onChange={(date) => {
                      setFilter({ ...filter, month: date.format("YYYY-MM") });
                    }}
                    format={"YYYY-MM"}
                  />
                </ConfigProvider>
                {/* <RangePicker
                  size='large'
                  allowClear={false}
                  defaultValue={[dayjs(dates.from), dayjs(dates.to)]}
                  onChange={(dates) => {
                    setDates({
                      from: dates[0].format("YYYY-MM-DD"),
                      to: dates[1].format("YYYY-MM-DD"),
                    });
                  }}
                  format={"YYYY-MM-DD"}
                /> */}
              </div>
            </div>
          </div>
          {/* <Button onClick={showModal} gradientMonochrome="purple" className="mt-5 md:mt-0">Ajouter</Button> */}
        </div>
        <div className="flex gap-2 mt-20 mb-5">
          <div className="flex items-center ms-2">
            <span className="text-sm me-2">Filtrer par statut</span>
            <select
              size='large'
              value={filter.status ? filter.status : 'ALL'}
              className="border border-gray-300 rounded-md px-2 py-1 text-sm"
              onChange={(value) => {
                if (value.target.value === 'ALL') {
                  setFilter({ ...filter, status: null })
                } else
                  setFilter({ ...filter, status: value.target.value })
              }}
            >
              <option value='ALL'>Tous</option>
              <option value='TREATED'>Traité</option>
              <option value='PENDING'>Non traité</option>
            </select>
          </div>
          <div className="flex items-center ms-2">
            <span className="text-sm me-2">Filtrer par type</span>
            <select
              size='large'
              value={filter.type ? filter.type : 'ALL'}
              className="border border-gray-300 rounded-md px-2 py-1 text-sm"
              onChange={(value) => {
                if (value.target.value === 'ALL') {
                  setFilter({ ...filter, type: null })
                } else
                  setFilter({ ...filter, type: value.target.value })
              }}
            >
              <option value='ALL'>Tous</option>
              <option value='VS'>Variables salariales</option>
              <option value='EMPLOYEE_ABSENCE'>Absences des salariés</option>
              <option value='EMPLOYEE_REENTRY'>Retours des salariés</option>
              <option value='EMPLOYEE_CREATION'>Nouveaux salariés</option>
              <option value='EMPLOYEE_DEPARTURE'>Départs des salariés</option>

            </select>
          </div>
        </div>
        <ConfigProvider locale={fr_FR}>
          <Table
            className="mt-5"
            dataSource={declarations}
            loading={!declarations}
            columns={[
              {
                title: "Société",
                dataIndex: "company",
                key: "company",
                render: (company) => {
                  return company?.name;
                },
              },
              {
                title: "Type",
                dataIndex: "type",
                key: "type",
                render: (type, record) => {
                  return type.includes('VS_') ? 'Variables salariales' : type === 'EMPLOYEE_ABSENCE' ? 'Absence de salarié ' + record?.employee?.firstname + ' ' + record?.employee?.lastname : type === 'EMPLOYEE_REENTRY' ? 'Retour de salarié ' + record?.employee?.firstname + ' ' + record?.employee?.lastname : type === 'EMPLOYEE_CREATION' ? 'Nouveau salarié ' + record?.employee?.firstname + ' ' + record?.employee?.lastname : type === 'EMPLOYEE_DEPARTURE' ? 'Départ de salarié ' + record?.employee?.firstname + ' ' + record?.employee?.lastname : type;
                },
              },
              {
                title: "Statut",
                dataIndex: "status",
                key: "status",
                render: (status, record) => {
                  return <Tag color={status === 'TREATED' ? 'green' : 'orange'}>{status === 'TREATED' ? ('Traité le ' + moment(record?.updatedAt).format('DD/MM/YYYY, HH:mm')) : 'Non traité'}</Tag>
                },
              },
              {
                title: "Date",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (createdAt) => {
                  return moment(createdAt).format('DD/MM/YYYY, HH:mm');
                },
              },
              {
                title: "Actions",
                dataIndex: "id",
                key: "id",
                render: (id, record) => {
                  return (
                    <div className="flex items-center space-x-2">
                      {/* <button className="bg-blue-500 text-white px-2 py-1 rounded-md hover:bg-blue-600">Voir</button> */}
                      {/* <button className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600">Supprimer</button> */}
                      {
                        <button className="bg-blue-500 text-white px-2 py-1 rounded-md hover:bg-blue-600" onClick={() => {
                          if (record?.type == 'EMPLOYEE_ABSENCE') {
                            navigate(`/absences?company=${record?.company?.id}&employee=${record?.employee?.id}`);
                          } else if (record?.type == 'EMPLOYEE_REENTRY' || record?.type == 'EMPLOYEE_CREATION' || record?.type == 'EMPLOYEE_DEPARTURE') {
                            navigate(`/employees?company=${record?.company?.id}&employee=${record?.employee?.id}`);
                          } else if (record?.type.includes('VS_')) {
                            navigate(`/variables?company=${record?.company?.id}`);
                          }
                        }}>
                          Voir
                        </button>
                      }
                      {
                        record?.status === 'TREATED' ? '' : (
                          <button className="bg-green-500 text-white px-2 py-1 rounded-md hover:bg-green-600" onClick={() => {
                            Confirmation({
                              title: 'Marquer comme traité',
                              content: 'Voulez-vous vraiment marquer cette déclaration comme traitée ?',
                              onConfirm: async () => {
                                try {
                                  await userRequest.put(`declaration/${id}/treat`);
                                  fetchDeclarations();
                                }
                                catch (error) {
                                  console.error('Error:', error);
                                }
                              }
                            })
                          }}>Marquer comme traité</button>)
                      }
                    </div>
                  )
                }
              }
            ]}
            pagination={{ pageSize: 10 }}
          />
        </ConfigProvider>
      </main>
    </div>
  );
};

export default Declarations;
