import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

export const pagesAccess = [
  'liste_des_salariees', 'liste_des_salariees_consulter', 'liste_des_salariees_ajouter', 'liste_des_salariees_modifier', 'liste_des_salariees_supprimer',
  'les_variables_salariales', 'les_variables_salariales_consulter', 'les_variables_salariales_traiter', 'les_variables_salariales_declarer',
  'gestion_des_absences', 'gestion_des_absences_consulter', 'gestion_des_absences_traiter',
  'gestion_des_comptes', 'gestion_des_comptes_consulter', 'gestion_des_comptes_ajouter', 'gestion_des_comptes_modifier', 'gestion_des_comptes_supprimer',
  'liste_des_entreprises', 'liste_des_entreprises_consulter', 'liste_des_entreprises_ajouter', 'liste_des_entreprises_modifier', 'liste_des_entreprises_supprimer',
  'gestion_des_declaration', 'gestion_des_declaration_consulter', 'gestion_des_declaration_traiter', 'gestion_des_declaration_declarer',
  'historique_des_activites', 'historique_des_activites_consulter',
];
const token = Cookies.get('token');

export const isSuperAdmin = () => {
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken?.roles?.includes('ROLE_SUPER_ADMIN');
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const isAdmin = () => {
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken?.roles?.includes('ROLE_ADMIN') || decodedToken?.roles?.includes('ROLE_SUPER_ADMIN');
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const isUser = () => {
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken?.roles?.includes('ROLE_USER');
    } catch (error) {
      return false;
    }
  }
  return false;
}

export const checkPageAccess = (value) => {
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return (decodedToken?.roles?.includes('ROLE_SUPER_ADMIN') || decodedToken?.pages_access?.includes(value)) ? true : false;
    } catch (error) {
      return false;
    }
  }
  return false;
}

export const hasMultipleCompanies = () => {
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken?.company === 'multiple'
    } catch (error) {
      return false;
    }
  }
  return false;
}

export const getCompany = () => {
  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      return decodedToken?.company;
    } catch (error) {
      return null;
    }
  }
  return null;
}
